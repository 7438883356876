import React, { useEffect, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
// import GoogleMapReactLanding from './GoogleMapReactLanding'
import ScriptLoadContainer from '../../common/ScriptLoadContainer';
// import LandingPickUpDnaRow from './dna/DnaRow/LandingPickUpDnaRow';
import Header from '../../common/Header';
// import LandingInstruction from './LandingInstruction'
import {
	setInputFocusing,
	setHeaderMargin,
} from '../../../redux/actions/common';
import { push } from 'connected-react-router';
import LandingContents from './landingContentsDesktop/LandingContents';

const AsyncRightMovingCompany = lazy(() => import('./RightMovingCompany'));
const AsyncHowUnpaktWorks = lazy(() => import('./HowUnpaktWorks'));
const AsyncDontJustTake = lazy(() => import('./DontJustTake'));
const AsyncFewOfManyServices = lazy(() => import('./FewOfManyServices'));
// const AsyncEnjoyGreatOffers = lazy(() => import("./EnjoyGreatOffers"));
const AsyncLandingFooter = lazy(() => import('./LandingFooter'));

const mapStateToProps = ({ common }) => ({
	inputFocusing: common.inputFocusing,
});

export const Landing = ({
	viewModel,
	inputFocusing,
	setInputFocusing,
	setHeaderMargin,
	push,
}) => {
	useEffect(() => {
		setInputFocusing(false);
		setHeaderMargin(true);
		//eslint-disable-next-line
	}, []);

	return (
		<div className="landing">
			<div>
				<Header />
				<div className="landing-title">
					<h1>
						Book <span>your move</span>
					</h1>
					<h1>online in minutes!</h1>
				</div>
			</div>

			<ScriptLoadContainer
				script="google"
				src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`}>
				{/* <LandingPickUpDnaRow /> */}
				<div className="w-100 getquotes__container">
					<button onClick={() => push('/address')} className="getquotes__btn">
						Get Quotes
					</button>
				</div>
				{/* <div class='gradient-div'></div> */}
				{/* <GoogleMapReactLanding coords={coords} zoom={11} /> */}

				{viewModel === 'mobile' ? (
					// <img src="/img/landingBody/hero-img--mobile.png" alt="" />
					<div style={{ height: '10vh' }}></div>
				) : (
					<>
						{/* <div className="landing-img__container">
              <div className="landing-img__overlay"></div>
            </div> */}
						<div style={{ height: '8vh' }}></div>
						{/* <LandingInstruction viewModel={viewModel} inputFocusing={inputFocusing} /> */}
						<LandingContents viewModel={viewModel} />
					</>
				)}
			</ScriptLoadContainer>
			{viewModel === 'mobile' && (
				<>
					<Suspense fallback={''}>
						<AsyncRightMovingCompany />
					</Suspense>
					<Suspense fallback={''}>
						<AsyncHowUnpaktWorks viewModel={viewModel} />
					</Suspense>

					<div className="content-button__container">
						<button
							className="content-button--black mt-5"
							onClick={() => push('/address')}>
							Get Started
						</button>
					</div>

					<Suspense fallback={''}>
						<AsyncDontJustTake />
					</Suspense>
					<Suspense fallback={''}>
						<AsyncFewOfManyServices />
					</Suspense>
					{/* <Suspense fallback={''}><AsyncEnjoyGreatOffers /></Suspense> */}

					<div className="content-button__container">
						<button
							className="content-button--black mt-5"
							onClick={() => push('/address')}>
							Get Started
						</button>
					</div>

					<Suspense fallback={''}>
						<AsyncLandingFooter />
					</Suspense>
				</>
			)}
		</div>
	);
};

export default connect(mapStateToProps, {
	setInputFocusing,
	setHeaderMargin,
	push,
})(Landing);

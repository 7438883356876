import { INIT_CONGRATS_PAGE } from '../actionTypes';
// import { getCurrentMoveplan, setCurrentMoveplanID } from '../actions/movePlan';
import { setCurrentMoveplanID } from '../actions/movePlan';
import { setCurrentStep } from '../actions/funnel';
// import { fetchInventory, setInventoryLoading } from '../actions/inventory';
import { setInventoryLoading } from '../actions/inventory';

const congratsMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);
		if (action.type === INIT_CONGRATS_PAGE) {
			dispatch(setInventoryLoading(true));
			dispatch(setCurrentStep('CONGRATS'));
			dispatch(setCurrentMoveplanID(action.payload));
			// dispatch(getCurrentMoveplan());
			// dispatch(fetchInventory(action.payload));
		}
	};

export default congratsMiddleware;

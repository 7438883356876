import React from 'react';
import DatePicker from 'react-datepicker';
import { calcTwoBusinessDays } from '../../../../redux/reducers/movePlan';
import { addYears } from 'date-fns';
import EditRoundedIcon from '@material-ui/icons/EditRounded';

const MoveDate = props => {
	const handleChange = value => {
		props.setFieldValue(props.addressKey, value);
	};
	const handleDateChangeRaw = e => {
		e.preventDefault();
	};
	return (
		<div className="move-date-container">
			<div className="move-date-svg">
				<svg
					id="Layer_1"
					data-name="Layer 1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 34 34">
					<path d="M13.71,17.9h-2v2h2Zm4.16,0h-2v2h2Zm4.35,0h-2v2h2Zm0,4.07h-2v2h2Zm-4.35,0h-2v2h2Zm-4.16,0h-2v2h2ZM26,13.18c0-.77-.1-1.71-1.62-1.71H22.43V10.4a1.42,1.42,0,0,0-.86-1.29A1.46,1.46,0,0,0,21,9a1.41,1.41,0,0,0-1.41,1.4v1.07H14.52v-1a1.44,1.44,0,0,0-.24-.79,1.4,1.4,0,0,0-1.17-.61,1.38,1.38,0,0,0-.78.23,1.41,1.41,0,0,0-.63,1.17v1h-2C8.24,11.47,8,12.13,8,12.9V15s0,0,0,0a.5.5,0,0,0,0,.12V26.5a.5.5,0,0,0,.5.5h17a.51.51,0,0,0,.51-.5V15.16s0,0,0-.07a.36.36,0,0,0,0-.1ZM25,26H9V15.67H25Z" />
				</svg>
			</div>
			<label className="move-date-label" htmlFor="react-date-picker">
				Select Move Date
			</label>
			<div className="date-picker-container">
				<DatePicker
					className={'date-pick-input-class'}
					selected={props.values.move_date}
					onChange={handleChange}
					onChangeRaw={handleDateChangeRaw}
					onBlur={props.handleBlur}
					minDate={calcTwoBusinessDays()}
					maxDate={addYears(Date.now(), 1)}
					dateFormat="MMM d, yyyy"
					id="react-date-picker"
					withPortal
				/>
			</div>
			<div className="move-date-edit">
				<EditRoundedIcon />
			</div>
		</div>
	);
};

export default MoveDate;

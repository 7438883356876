import { handleActions } from 'redux-actions';
import {
	SET_EMAIL_TAKEN,
	SET_USER,
	SET_ERROR_MSG,
	UPDATE_FACEBOOK_AUTH,
	SET_AUTH_MODAL_VIEW,
	SET_AUTH_SUBMITTING,
	UNSET_USER,
	SET_ROLE,
	SET_RESET_EMAIL,
	SET_LAST_MPID,
	SET_AUTH_REDIRECT,
	SET_ALL_MOVEPLANS,
} from '../actionTypes';
import omit from 'lodash-es/omit';

const initialState = {
	isMover: false,
	isAdmin: false,
	isSuperAdmin: false,
	isEmailTaken: false,
	facebookResponse: {},
	authModalView: 'login',
	authErrorText: '',
	submitting: false,
	resetEmail: undefined,
	lastMPID: '',
	authRedirect: '/',
};

const authReducer = handleActions(
	{
		[SET_ROLE]: (state, { payload }) => {
			switch (payload.role) {
				case 0:
					return {
						...state,
						isAdmin: true,
						isSuperAdmin: true,
						isMover: false,
					};
				case 1:
					return {
						...state,
						isAdmin: true,
						isSuperAdmin: false,
						isMover: false,
					};
				case 2:
					return {
						...state,
						isAdmin: false,
						isSuperAdmin: false,
						isMover: true,
					};
				case 3:
					return {
						...state,
						isAdmin: false,
						isSuperAdmin: false,
						isMover: false,
					};
				default:
					return {
						...state,
						isAdmin: false,
						isSuperAdmin: false,
						isMover: false,
					};
			}
		},
		[SET_EMAIL_TAKEN]: (state, action) => ({
			...state,
			isEmailTaken: action.payload,
		}),
		[SET_USER]: (state, { payload }) => ({
			...state,
			user: payload,
		}),
		[SET_ERROR_MSG]: (state, { payload }) => ({
			...state,
			authErrorText: payload,
		}),
		[UPDATE_FACEBOOK_AUTH]: (state, { payload }) => ({
			...state,
			facebookResponse: payload,
		}),
		[SET_AUTH_MODAL_VIEW]: (state, { payload }) => ({
			...state,
			authModalView: payload,
		}),
		[SET_AUTH_SUBMITTING]: (state, { payload }) => ({
			...state,
			submitting: payload,
		}),
		[UNSET_USER]: state => omit(state, 'user'),
		[SET_RESET_EMAIL]: (state, { payload }) => ({
			...state,
			resetEmail: payload,
		}),
		[SET_LAST_MPID]: (state, { payload }) => ({
			...state,
			lastMPID: payload,
		}),
		[SET_AUTH_REDIRECT]: (state, { payload }) => ({
			...state,
			authRedirect: payload,
		}),
		[SET_ALL_MOVEPLANS]: (state, { payload }) => ({
			...state,
			moveplans: payload,
		}),
	},
	initialState
);

export const canEdit = (movePlan, auth) =>
	(!movePlan.currentPlan.is_completed &&
		!movePlan.currentPlan.is_done &&
		!movePlan.currentPlan.read_only_plan &&
		!auth.isMover) ||
	(auth.isAdmin && !movePlan.currentPlan.read_only_plan);

export const isLoggedIn = auth =>
	!!auth.user ? Object.keys(auth.user).length > 0 : false;

export default authReducer;

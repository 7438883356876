import {
	CHECK_COOKIES,
	CHECK_EMAIL_AVAILABILITY,
	FACEBOOK_LOGIN,
	GOOGLE_LOGIN,
	NETWORK_FAILURE,
	NETWORK_SUCCESS,
	REGISTER_USER,
	RESET_PASSWORD,
	SET_USER,
	SIGN_IN,
	SIGN_OUT,
	CHECK_IS_MOBILE,
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
	setErrorMessage,
	setAuthSubmitting,
	setEmailTaken,
	setUser,
	unsetUser,
	setRole,
	setResetEmail,
} from '../actions/auth';
import { submitBook, setBookLoading } from '../actions/book';
import { closeModal } from '../actions/modals';
import has from 'lodash/has';
import { push } from 'connected-react-router';
import { setIsMobile } from '../actions/ui/mobile';

const authMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		// todo: verify that this works
		if (action.type === CHECK_EMAIL_AVAILABILITY) {
			dispatch(
				apiRequest({
					url: `api/users/check_email_availability?user[email]=${action.payload}`,
					method: 'GET',
					fromAction: CHECK_EMAIL_AVAILABILITY,
				})
			);
		}

		if (action.type === REGISTER_USER) {
			let params = {
				user: action.payload,
				newflow: true,
			};
			if (getState().movePlan.currentMPID) {
				params.move_plan_id = getState().movePlan.currentMPID;
			}
			dispatch(
				apiRequest({
					url: '/users',
					method: 'POST',
					params: params,
					fromAction: REGISTER_USER,
					callback: !!action.meta.context ? action.meta.context : false,
				})
			);
		}
		if (action.type === SET_USER) {
			// getState().auth.cookies.set('role', 'user', { path: '/login/' })
			// getState().auth.cookies.set('name', action.payload, { path: '/login/' })
		}

		if (action.type === SIGN_IN) {
			dispatch(setAuthSubmitting(true));
			let params = {
				user: {
					...action.payload,
					remember_me: false,
				},
				newflow: true,
			};
			if (getState().movePlan.currentMPID) {
				params.move_plan_id = getState().movePlan.currentMPID;
			}
			dispatch(
				apiRequest({
					url: `/users/login`,
					method: 'POST',
					params: params,
					fromAction: SIGN_IN,
					redirect: has(action.meta, 'redirect') ? action.meta.redirect : false,
					callback: has(action.meta, 'context') ? action.meta.context : false,
				})
			);
		}

		if (action.type === SIGN_OUT) {
			// todo: make sure the proper path and method are implemented here
			dispatch(
				apiRequest({
					url: `/users/logout`,
					method: 'DELETE',
					params: {
						newflow: true,
					},
					fromAction: SIGN_OUT,
				})
			);
		}

		if (action.type === FACEBOOK_LOGIN) {
			let fromBooking = action.payload;
			let mpid = getState().movePlan.currentMPID;
			// https://www.facebook.com/login.php
			// ?skip_api_login=1&api_key=1002206536583234&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv2.12%2Fdialog%2Foauth%3F
			// redirect_uri%3Dhttp%253A%252F%252Flocalhost%253A3000%252Fusers%252Fauth%252Ffacebook%252Fcallback%26state%3Daec96ab7b9b394cbb20f1da87e1e6b925cc04708a0224001%26scope%3Demail%26response_type%3Dcode%26client_id%3D1002206536583234%26ret%3Dlogin%26logger_id%3D06db2fbe-4f20-4ffc-5ae4-1d0c76965abb&cancel_url=http%3A%2F%2Flocalhost%3A3000%2Fusers%2Fauth%2Ffacebook%2Fcallback%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%26state%3Daec96ab7b9b394cbb20f1da87e1e6b925cc04708a0224001%23_%3D_&display=page&locale=en_US&logger_id=06db2fbe-4f20-4ffc-5ae4-1d0c76965abb
			let planParam = getState().movePlan.currentMPID
				? `&move_plan_id=${mpid}`
				: '';
			// console.log(` href = ${process.env.REACT_APP_BASE_URL}/users/auth/facebook?redirect_url=${returnUrl(mpid, fromBooking)}${planParam}`)
			window.location.href = `${
				process.env.REACT_APP_BASE_URL
			}/users/auth/facebook?redirect_url=${returnUrl(
				mpid,
				fromBooking
			)}${planParam}`;
		}

		if (action.type === GOOGLE_LOGIN) {
		}

		if (action.type === CHECK_COOKIES) {
			// %7B%22role%22%3A%22super_admin%22%2C%22name%22%3Anull%2C%22email%22%3A%22superadmin%40unpakt.com%22%7D
			let cookies = {};
			action.payload.split(/\s*;\s*/).forEach(function (pair) {
				pair = pair.split(/\s*=\s*/);
				cookies[pair[0]] = pair.splice(1).join('=');
			});

			if (has(cookies, 'login_info')) {
				let creds = JSON.parse(decodeURIComponent(cookies.login_info));
				dispatch(
					setUser({ email: creds.email, role: creds.role, name: creds.name })
				);
				roleHelper(dispatch, creds.role);
			} else {
				dispatch(setUser(null));
				dispatch(setRole(null, false));
			}
		}

		if (action.type === RESET_PASSWORD) {
			dispatch(setAuthSubmitting(true));
			dispatch(setResetEmail(action.payload));
			dispatch(
				apiRequest({
					url: '/users/password.json',
					method: 'POST',
					params: {
						user: {
							email: action.payload,
						},
					},
					fromAction: RESET_PASSWORD,
				})
			);
		}

		if (action.type === CHECK_IS_MOBILE) {
			let isMobile = false;
			if (window.innerWidth < 500) {
				isMobile = true;
			} else if (navigator.maxTouchPoints === undefined) {
				// check the user agent info to see if iOs or desktop
				if (
					!!navigator.userAgent.match(
						/iPad|iPhone|Android|BlackBerry|Windows Phone|webOS/i
					)
				)
					isMobile = true;
				else if (navigator.userAgent.match(/SamsungBrowser/i)) isMobile = true;
				else {
					if (navigator.maxTouchPoints > 0 && isMobile === false) {
						// this browser has touch
						if (window.innerWidth <= 1080) {
							// this is most likely a mobile device
							isMobile = true;
						} else isMobile = false;
					}
				}
			}
			dispatch(setIsMobile(isMobile));
		}

		if (action.type === NETWORK_SUCCESS) {
			let { data } = action.payload.response;
			switch (action.meta.originalRequest.fromAction) {
				case CHECK_EMAIL_AVAILABILITY:
					dispatch(setEmailTaken(!data.available));
					break;
				case REGISTER_USER:
					dispatch(setUser(data.user.user));
					if (!!action.meta.originalRequest.callback) {
						if (action.meta.originalRequest.callback === 'book')
							dispatch(submitBook());
					} else dispatch(closeModal());
					break;
				case SIGN_IN:
					dispatch(setUser(data.user.user));
					roleHelper(dispatch, data.user.user.role);
					dispatch(setAuthSubmitting(false));
					dispatch(closeModal());
					if (!!action.meta.originalRequest.redirect) {
						dispatch(push(action.meta.originalRequest.redirect));
					}
					if (!!action.meta.originalRequest.callback) {
						if (action.meta.originalRequest.callback === 'book')
							dispatch(submitBook());
					}
					break;
				case SIGN_OUT:
					dispatch(setRole(null, false));
					dispatch(unsetUser());
					localStorage.removeItem('persist:root');
					dispatch(setAuthSubmitting(false));
					setTimeout(() => dispatch(push('/')), 300);
					break;
				case FACEBOOK_LOGIN:
					//todo: handle facebook login success
					break;
				case RESET_PASSWORD:
					dispatch(
						setResetEmail(action.meta.originalRequest.params.user.email)
					);
					dispatch(setErrorMessage(null));
					dispatch(setResetEmail('success'));
					dispatch(setAuthSubmitting(false));
					break;
				default:
					break;
			}
		}
		if (action.type === NETWORK_FAILURE) {
			switch (action.meta.originalRequest.fromAction) {
				case SIGN_IN:
					if (!!action.payload.response.response) {
						if (action.payload.response.response.status === 401) {
							dispatch(setErrorMessage('Incorrect username or password'));
						}
					} else {
						sessionStorage.clear();
						dispatch(
							setErrorMessage(
								'Network Error: Unauthorized \n Please refresh your browser'
							)
						);
					}
					if (!!getState().book.loading) dispatch(setBookLoading(false));
					dispatch(setAuthSubmitting(false));
					break;
				case REGISTER_USER:
					if (!!action.payload.response.response) {
						console.log(action.payload.response);
						if (action.payload.response.response.status === 401) {
							dispatch(setErrorMessage('Incorrect username or password'));
						}
						if (getState().book.loading) dispatch(setBookLoading(false));
					} else {
						sessionStorage.clear();
						dispatch(
							setErrorMessage(
								'Network Error: Unauthorized \n Please refresh your browser'
							)
						);
					}
					dispatch(setAuthSubmitting(false));
					break;
				case RESET_PASSWORD:
					dispatch(setErrorMessage('Your email was not found.'));
					dispatch(setResetEmail(null));
					dispatch(setAuthSubmitting(false));
					break;
				default:
					break;
			}
		}
	};

const returnUrl = (mpid, fromBooking) => {
	let base = window.location.origin;
	let state = !!fromBooking ? 'book' : 'plan';
	return mpid ? `${base}/${mpid}/${state}` : `${base}/myhome`;
};

const roleHelper = (dispatch, role) => {
	switch (role) {
		case 'super_admin':
			dispatch(setRole(0, true));
			break;
		case 'admin':
			dispatch(setRole(1, true));
			break;
		default:
			dispatch(setRole(4, true));
			break;
	}
};

export default authMiddleware;

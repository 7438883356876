import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { connect } from 'react-redux';
import { addDays, format } from 'date-fns';
import ScriptLoadContainer from '../../common/ScriptLoadContainer';
import GoogleMapReact from './GoogleMapReact';
import DnaFormTop from './dna/DnaFormTop';
import DnaFormBot from './dna/DnaFormBot';
import { submitDNA } from '../../../redux/actions/movePlan';
import { setHeaderMargin, setMoveMapTop } from '../../../redux/actions/common';

const mapStateToProps = ({ movePlan, common }) => ({
	pick_up: movePlan.currentPlan.pick_up ? movePlan.currentPlan.pick_up : null,
	drop_off: movePlan.currentPlan.drop_off
		? movePlan.currentPlan.drop_off
		: null,
	inputFocusing: common.inputFocusing,
});

const LandingDna = props => {
	const coords = { lat: 40.763365, lng: -73.975654 };
	const [showHomeSize, setShowHomeSize] = useState(false);

	useEffect(() => {
		props.setHeaderMargin(false);
		//eslint-disable-next-line
	}, []);

	const handleContinue = () => {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			event: 'MoveNow Landing DNA',
			eventCategory: 'MoveNow Landing DNA',
			eventAction: 'MoveNow booking:started',
			eventLabel: '',
			eventValue: '',
		});
		props.setMoveMapTop(true);
		setShowHomeSize(true);
	};

	return (
		<Formik
			initialValues={{
				pick_up: props.pick_up ? props.pick_up : '',
				drop_off: props.drop_off ? props.drop_off : '',
				home_size_id: 1,
				move_date: addDays(Date.now(), 10),
				extra_drop_off: '',
			}}
			handleSubmit={e => e.preventDefault()}
			onSubmit={values => {
				let extra_drop_off_enabled = false;
				if (values.extra_drop_off) {
					extra_drop_off_enabled = true;
				}
				props.submitDNA({
					pick_up_address: values.pick_up,
					drop_off_address: values.drop_off,
					extra_drop_off_address: values.extra_drop_off,
					extra_drop_off_enabled: extra_drop_off_enabled,
					home_size_id: values.home_size_id,
					move_date: format(values.move_date, 'MM/dd/yyyy'),
					address_src: 'gmaps',
					white_label_name: 'Move Now',
				});
			}}>
			{({
				values,
				touched,
				handleSubmit,
				isSubmitting,
				setFieldValue,
				setFieldError,
				setFieldTouched,
				handleBlur,
				handleChange,
			}) => (
				<form onSubmit={handleSubmit} noValidate>
					<ScriptLoadContainer
						script="google"
						src={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`}>
						{!showHomeSize ? (
							<DnaFormTop
								touched={touched}
								values={values}
								setFieldValue={setFieldValue}
								setFieldError={setFieldError}
								setFieldTouched={setFieldTouched}
								handleChange={handleChange}
							/>
						) : (
							<DnaFormBot
								values={values}
								setFieldValue={setFieldValue}
								handleBlur={handleBlur}
								isSubmitting={isSubmitting}
							/>
						)}
						{props.pick_up && props.drop_off && (
							<GoogleMapReact coords={coords} zoom={11} />
						)}
					</ScriptLoadContainer>
					{!showHomeSize && (
						<button
							disabled={
								!values.drop_off.geocoded_address ||
								!values.pick_up.geocoded_address
							}
							type="button"
							onClick={() => handleContinue()}
							className={`continue-btn ${
								props.inputFocusing && props.viewModel === 'mobile'
									? ''
									: 'continue-btn-fixed'
							}`}>
							<p>Continue</p>
						</button>
					)}
				</form>
			)}
		</Formik>
	);
};

export default connect(mapStateToProps, {
	submitDNA,
	setHeaderMargin,
	setMoveMapTop,
})(LandingDna);

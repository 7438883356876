import {
	FILTER_COMPARE_RESULTS,
	GET_BIDS,
	GET_PRICING_BREAKDOWN,
	INIT_COMPARE_PAGE,
	NETWORK_SUCCESS,
	SELECT_MOVER,
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import {
	addDeliveryEstimateOption,
	filterCompareResults,
	getBids,
	setBidPricingBreakdown,
	setBids,
	setCompareLoading,
	setFilteredResults,
} from '../actions/compare';

import orderBy from 'lodash/orderBy';
import { setCurrentStep } from '../actions/funnel';
import { getCurrentMoveplan, setCurrentMoveplanID } from '../actions/movePlan';
import { addInventoryPricing } from '../actions/inventory';

const compareMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		if (action.type === INIT_COMPARE_PAGE) {
			dispatch(setCompareLoading(true));
			dispatch(setCurrentStep('COMPARE'));
			dispatch(setCurrentMoveplanID(action.payload));
			dispatch(getCurrentMoveplan());
			dispatch(getBids());
		}
		if (action.type === GET_BIDS) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${getState().movePlan.currentMPID}/movers`,
					method: 'GET',
					fromAction: GET_BIDS,
				})
			);
		}

		if (action.type === FILTER_COMPARE_RESULTS) {
			let filtered = [];
			let tempBids = getState().compare.bids.filter(
				bid => bid.consult_only === false
			);
			switch (action.payload) {
				case 1:
					filtered = orderBy(tempBids, ['total_cost'], ['asc']);
					break;
				case 2:
					filtered = orderBy(tempBids, ['total_cost'], ['desc']);
					break;
				case 3:
					tempBids.map(
						bid =>
							(bid.total_reviews =
								bid.ratings_data.google.number_of_reviews +
								bid.ratings_data.yelp.number_of_reviews +
								bid.ratings_data.unpakt.number_of_reviews)
					);
					filtered = orderBy(tempBids, ['total_reviews'], ['desc']);
					break;
				case 4:
					// TODO: this should be tested at some point with long range moving
					filtered = tempBids.sort((a, b) => {
						if (a.delivery_estimate && b.delivery_estimate) {
							let aMax = a.delivery_estimate.maximum_delivery_days;
							let bMax = b.delivery_estimate.maximum_delivery_days;
							let aMin = a.delivery_estimate.minimum_delivery_days;
							let bMin = b.delivery_estimate.minimum_delivery_days;

							if (aMax && bMax && aMin && bMin) {
								if (aMax === bMax && aMin === bMin) {
									return a.total_cost - b.total_cost;
								} else if (aMax === bMax) {
									return aMin - bMin;
								} else return aMax - bMax;
							} else if (!aMax) {
								return 1;
							} else if (!bMax) {
								return -1;
							}
						}
						return -1;
					});
					break;
				default:
					console.log('improper filter valued applied to compare results');
					break;
			}
			dispatch(setFilteredResults(filtered));
		}

		if (action.type === SELECT_MOVER) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${
						getState().movePlan.currentMPID
					}/choose_mover`,
					method: 'POST',
					params: {
						id: action.payload,
					},
					fromAction: SELECT_MOVER,
				})
			);
		}
		if (action.type === GET_PRICING_BREAKDOWN) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${
						getState().movePlan.currentMPID
					}/estimates/for_mover`,
					method: 'GET',
					params: {
						mover_id: action.payload,
					},
					fromAction: GET_PRICING_BREAKDOWN,
				})
			);
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case GET_BIDS:
					const { estimates: bidData } = action.payload.response.data;
					let temp = bidData.map(mover => {
						//eslint-disable-next-line
						for (let key in mover) {
							if (key === 'delivery_estimate') {
								console.log(mover, key);
							}
						}
						return true;
					});
					if (temp.some(bid => bid === true)) {
						dispatch(addDeliveryEstimateOption());
					}
					dispatch(setBids(bidData));
					dispatch(filterCompareResults(1));
					dispatch(setCompareLoading(false));
					break;
				case GET_PRICING_BREAKDOWN:
					dispatch(setBidPricingBreakdown(action.payload.response.data));
					dispatch(addInventoryPricing());
					break;
				case SELECT_MOVER:
					dispatch(getCurrentMoveplan());
					// dispatch(nextStep());
					break;
				default:
					break;
			}
		}
	};

export default compareMiddleware;

// $http.get "#{API_URL}/move_plans/#{MovePlan.uuid()}/movers"

import {
	GOTO_BOOK,
	GOTO_BOXES,
	GOTO_CONGRATS,
	GOTO_LAST_STEP,
	GOTO_MY_INVENTORY,
	GOTO_PLAN,
	GOTO_STEP,
	NETWORK_SUCCESS,
	NEXT_STEP,
	PRINT_PAGE,
	GOTO_DETAILS,
	IMPORT_FUNNEL_STATE,
	SET_STEP,
	GOTO_REVIEW,
} from '../actionTypes';
import {
	gotoBook,
	gotoCompare,
	gotoMyInventory,
	goToStep,
	setCurrentStep,
	setLastStep,
	setStepCompleted,
	// setStep
} from '../actions/funnel';
import { setInventoryView } from '../actions/inventory';
import { push } from 'connected-react-router';
import { allowedToVisit } from '../reducers/funnel';
import { apiRequest } from '../actions/api';
import { FUNNEL_STEPS } from '../../constants';

const funnelMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		if (action.type === NEXT_STEP) {
			let lastStep = getState().funnel.currentStep;
			switch (lastStep) {
				case 'INVENTORY':
					dispatch(setStepCompleted('INVENTORY'));
					break;
				case 'DETAILS':
					dispatch(setStepCompleted('DETAILS'));
					break;
				case 'BOOK':
					dispatch(setStepCompleted('BOOK'));
					break;
				default:
					break;
			}

			let nextStep = FUNNEL_STEPS[lastStep].nextStep;
			dispatch(setLastStep(lastStep));
			dispatch(
				push(
					`/moving/${getState().movePlan.currentMPID}/${nextStep.toLowerCase()}`
				)
			);
			// dispatch(setStep(nextStep, true));
		}

		if (action.type === SET_STEP) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans/${getState().movePlan.currentMPID}/set_step`,
					method: 'PATCH',
					params: {
						step: action.payload,
					},
					fromAction: SET_STEP,
					redirect: action.meta,
				})
			);
		}
		if (action.type === GOTO_MY_INVENTORY) {
			if (getState().funnel.currentStep === 'REVIEW')
				dispatch(setLastStep('REVIEW'));
			dispatch(setCurrentStep('INVENTORY'));
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/inventory`));
		}

		if (action.type === GOTO_DETAILS) {
			if (getState().funnel.currentStep === 'REVIEW')
				dispatch(setLastStep('REVIEW'));
			dispatch(setCurrentStep('DETAILS'));
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/details`));
		}

		if (action.type === GOTO_REVIEW) {
			dispatch(setCurrentStep('REVIEW'));
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/review`));
		}
		if (action.type === GOTO_PLAN) {
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`));
		}
		if (action.type === GOTO_BOOK) {
			if (allowedToVisit(getState(), 'BOOK')) {
				dispatch(setCurrentStep('BOOK'));
				dispatch(push(`/moving/${getState().movePlan.currentMPID}/book`));
			}
		}
		if (action.type === GOTO_CONGRATS) {
			dispatch(push(`/moving/${getState().movePlan.currentMPID}/congrats`));
		}

		if (action.type === GOTO_BOXES) {
			dispatch(setInventoryView('BOXES'));
			//dispatch(push(`/moving/${getState().movePlan.currentMPID}/inventory`));
		}

		if (action.type === GOTO_STEP) {
			console.log(allowedToVisit(getState(), action.payload));
			switch (action.payload) {
				case 'INVENTORY':
					dispatch(gotoMyInventory());
					break;
				case 'DETAILS':
					if (allowedToVisit(getState(), action.payload)) {
						dispatch(
							push(`/moving/${getState().movePlan.currentMPID}/details`)
						);
					}
					break;
				case 'COMPARE':
					console.log(allowedToVisit(getState(), action.payload));
					if (allowedToVisit(getState(), action.payload)) {
						dispatch(gotoCompare());
					}
					break;
				case 'PLAN':
					if (allowedToVisit(getState(), action.payload)) {
						dispatch(setCurrentStep('PLAN'));
						dispatch(push(`/moving/${getState().movePlan.currentMPID}/plan`));
					}
					break;
				case 'BOOK':
					if (allowedToVisit(getState(), action.payload)) {
						dispatch(gotoBook());
					}
					break;
				default:
					alert('you managed to break the routing. great job ethan....');
					console.log('something went wrong with step selection');
					break;
			}
		}

		if (action.type === GOTO_LAST_STEP) {
			dispatch(
				goToStep(getState().movePlan.currentPlan.progress_state.toUpperCase())
			);
		}

		if (action.type === PRINT_PAGE) {
			if (action.payload.isCurrentPage) {
				let content = document.getElementById('printmovePlan');
				let pri = document.getElementById('ifmcontentstoprint').contentWindow;
				pri.document.open();
				pri.document.write(content.innerHTML);
				pri.document.close();
				pri.focus();
				pri.print();
			} else {
				let content = document.getElementById('print-container');
				let pri = document.getElementById('ifmcontentstoprint').contentWindow;
				pri.document.open();
				pri.document.write(content.innerHTML);
				pri.document.close();
				pri.focus();
				pri.print();
				//signal to react to load movePlan component out of sight
				//prepare this to print
				// do the same as above
			}
		}

		if (action.type === IMPORT_FUNNEL_STATE) {
			const stateKeys = Object.keys(getState().funnel.progressBarSteps);
			const incomingState = action.payload.toUpperCase();
			const j = stateKeys.indexOf(incomingState);
			for (let i = 0; i < j; i++) {
				dispatch(setStepCompleted(stateKeys[i]));
			}
		}
		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case SET_STEP:
					let lastStep = getState().funnel.currentStep;
					let nextStep = getState().funnel.progressBarSteps[lastStep].nextStep;
					dispatch(setLastStep(lastStep));
					if (!!action.meta.originalRequest.redirect)
						dispatch(
							push(
								`/moving/${
									getState().movePlan.currentMPID
								}/${nextStep.toLowerCase()}`
							)
						);
					break;
				default:
					break;
			}
		}
	};

export default funnelMiddleware;

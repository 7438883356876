import {
	DELETE_PLAN,
	EMAIL_CHECKLIST,
	GET_ALL_PLANS,
	INIT_MYHOME,
	PRINT_CHECKLIST,
	REUSE_PLAN,
	SET_ALL_PLANS,
	START_NEW_PLAN,
	VIEW_PLAN,
} from '../actionTypes';

export const initMyHome = () => ({
	type: INIT_MYHOME,
});

export const emailChecklist = () => ({
	type: EMAIL_CHECKLIST,
});

export const setAllPlans = planData => ({
	type: SET_ALL_PLANS,
	payload: planData,
});

export const getAllPlans = () => ({
	type: GET_ALL_PLANS,
});

export const startNewPlan = () => ({
	type: START_NEW_PLAN,
});

export const printChecklist = () => ({
	type: PRINT_CHECKLIST,
});

export const reusePlan = (uuid, move_plan) => ({
	type: REUSE_PLAN,
	payload: { uuid: uuid, move_plan: move_plan },
});

export const viewPlan = (plan, status) => ({
	type: VIEW_PLAN,
	payload: { plan, status },
});

export const deletePlan = index => ({
	type: DELETE_PLAN,
	payload: index,
});

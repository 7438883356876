import React from 'react';

const ToArrow = ({ small }) => {
	return (
		<div className="replaceify" data-icon="to-arrow">
			<svg className={small ? 'marker-small-arrow' : ''}>
				<use xlinkHref={'#to-arrow'} />
			</svg>
		</div>
	);
};

export default ToArrow;

import React from 'react';
import FromArrow from '../icons/FromArrow';
import ToArrow from '../icons/ToArrow';

const Marker = ({ text, iconType }) => {
	return (
		<div className="map-marker">
			<div className="tool-tip-container">
				{iconType === 'from' ? (
					<FromArrow small={true} />
				) : (
					<ToArrow small={true} />
				)}
				<div className="marker-tool-tip">
					<p className="marker-tool-tip-text">{text}</p>
				</div>
			</div>
			<div className="marker-circle"></div>
		</div>
	);
};

export default Marker;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import history from './redux/history';
import { PersistGate } from 'redux-persist/integration/react';

import App from './App';
import store from './redux/store';
import { persistStore } from 'redux-persist';

import TagManager from 'react-gtm-module';

TagManager.initialize({
	gtmId: 'GTM-P5M9JT',
});

let persistor = persistStore(store);

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<ConnectedRouter history={history}>
				<App />
			</ConnectedRouter>
		</PersistGate>
	</Provider>,
	document.getElementById('root')
);

export default persistor;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../../redux/reducers/auth';
import { getMoveplan } from '../../redux/actions/movePlan';
import { connect } from 'react-redux';
import { track } from '../../redux/actions/analytics';

const Arrow = ({ color }) => (
	<svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 8.3 14.5">
		<path
			d="M0.3,14.2c0.4,0.4,1.1,0.4,1.5,0l0,0L8,8c0.4-0.4,0.4-1.1,0-1.5l0,0L1.8,0.3c-0.4-0.4-1.1-0.4-1.5,0
    s-0.4,1.1,0,1.5l5.5,5.5l-5.5,5.5C-0.1,13.1-0.1,13.8,0.3,14.2z"
		/>
		<style jsx>
			{' '}
			{`
    svg {
      ${
				color === 'green'
					? 'width: 1rem; fill: #0abbb5; margin-left: 0;'
					: 'width: .7rem; fill:  #193749; margin-left: 1rem;'
			}
    `}
		</style>
	</svg>
);

const mapStateToProps = ({ auth, router, movePlan }) => ({
	isLoggedIn: isLoggedIn(auth),
	user: auth.user,
	router: router,
	shortId: movePlan.shortMPID,
});

const LeftNav = ({ isLoggedIn, user, router, track }) => {
	const [openMobileMenu, setOpenMobileMenu] = useState(
		router.location.pathname === '/my-home' ? true : false
	);
	const toggleMenu = () => {
		if (openMobileMenu) {
			document.getElementById('navbar').classList.add('slide-out-left');
			setTimeout(() => setOpenMobileMenu(!openMobileMenu), 300);
		} else {
			setOpenMobileMenu(!openMobileMenu);
		}
	};

	return (
		<>
			<div className="menu-toggle" onClick={() => toggleMenu()}>
				<div className={`rotating-arrow ${openMobileMenu ? 'rotated' : ''}`}>
					<Arrow color={'green'} />
				</div>
			</div>

			{openMobileMenu && (
				<>
					<div className="black-overlay" onClick={() => toggleMenu()}></div>
					<nav
						id="navbar"
						className="pt-0 pl-4 pr-4 mobile-settings slide-in-left">
						{isLoggedIn ? (
							<>
								<div className="mt-5 mb-5 text-center profile ">
									<div className=" name-holder position-relative">
										<div className="user-name">
											<h3 className="mt-0">{user.email}</h3>
										</div>
									</div>
								</div>

								<Link
									className="nav-button"
									onClick={() => {
										toggleMenu();
										track('clicked Move History tab');
									}}
									to="/my-home/move-history">
									<button className="menu-option">
										<div>
											<img
												className="mr-3"
												alt="icon"
												src="/assets/my_home_icons/move_history.png"
											/>
											<span style={{ fontSize: '16px' }}>View My Moves</span>
										</div>
										<Arrow />
									</button>
								</Link>
							</>
						) : (
							<>
								<p className="m-auto">
									Please sign into your account to access your dashboard.
								</p>
							</>
						)}
					</nav>
				</>
			)}

			<style jsx>
				{`
					nav {
						background: #fff;
						max-width: 320px;
						width: 100%;
						overflow: auto;
						height: calc(100vh - 70px);
						position: sticky;
						top: 70px;
						z-index: 5;
						padding-bottom: 1.5rem;
					}

					.black-overlay {
						position: fixed;
						width: 100vw;
						height: calc(100vh - 70px);
						background: black;
						background: radial-gradient(transparent, black);
						opacity: 0.4;
						z-index: 5;
					}

					.name-holder {
						border-bottom: 2px solid #0abbb5;
						height: 40px;
						margin-bottom: 5rem;
					}
					.mobile-settings {
						position: fixed;
						z-index: 5;
						box-shadow: 5px 0 20px 10px #0000003d;
						padding-bottom: 70px;
					}

					.menu-toggle {
						position: fixed;
						z-index: 6;
						width: 40px;
						height: 40px;
						background: white;
						border-radius: 0 1rem 1rem 0;
						${!openMobileMenu ? 'box-shadow: 5px 5px 5px #00000030;' : ''}
						background: #fff;
						display: flex;
						align-items: center;
						justify-content: center;
						transition-duration: 0.3s;
					}

					.rotating-arrow {
						transform: rotate(0deg);
						transition-duration: 0.3s;
					}
					.rotated {
						transform: rotate(180deg);
					}
					nav::-webkit-scrollbar {
						width: 0.5rem;
					}
					nav::-webkit-scrollbar-track {
						-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
					}
					nav::-webkit-scrollbar-thumb {
						background-color: #0abeeb;
						outline: none;
					}
					.menu-option {
						color: #193749;
						background: #eaeaea;
						border: none;
						outline: none;
						width: 100%;
						font-size: 1.6rem;
						padding: 1rem 2rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
						border-radius: 2px;
						margin: 2px 0;
						font-size: 1.4rem;

						// background: white;
						// border: 1px solid #0abee8;
						//padding: .7rem 1.5rem;
						//border-radius: 20px;
						// margin: 0 0 8px 0;
					}
					.menu-option:hover,
					.menu-option:active {
						background: #ebf9ff;
					}
					a.nav-button:hover {
						text-decoration: none;
					}
					.menu-option img {
						width: 25px;
						height: 25px;
					}
					.menu-option > div {
						display: flex;
						justify-content: center;
						align-items: center;
						text-align: left;
					}

					h3 {
						font-size: 1.6rem;
						font-weight: 600;
						color: #0abbb5;
					}
					.user-icon {
						width: 40px;
						height: 40px;
						border-radius: 0.5rem;
						border: 3px solid #31afd8;
						overflow: hidden;
						position: absolute;
						top: 15px;
					}
					.user-name {
						margin-left: 20px;
						height: 20px;
						position: absolute;
						top: 10px;
					}
					img {
						width: 100%;
					}

					.slide-in-left {
						-webkit-animation: slide-in-left 0.5s
							cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
						animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
							both;
					}
					.slide-out-left {
						-webkit-animation: slide-out-left 0.5s
							cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
						animation: slide-out-left 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53)
							both;
					}

					/* ----------------------------------------------
 * Generated by Animista on 2019-7-9 14:50:19
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

					/**
 * ----------------------------------------
 * animation swing-in-left-fwd
 * ----------------------------------------
 */

					@-webkit-keyframes slide-in-left {
						0% {
							-webkit-transform: translateX(-1000px);
							transform: translateX(-1000px);
							opacity: 0;
						}
						100% {
							-webkit-transform: translateX(0);
							transform: translateX(0);
							opacity: 1;
						}
					}
					@keyframes slide-in-left {
						0% {
							-webkit-transform: translateX(-1000px);
							transform: translateX(-1000px);
							opacity: 0;
						}
						100% {
							-webkit-transform: translateX(0);
							transform: translateX(0);
							opacity: 1;
						}
					}

					@-webkit-keyframes slide-out-left {
						0% {
							-webkit-transform: translateX(0);
							transform: translateX(0);
							opacity: 1;
						}
						100% {
							-webkit-transform: translateX(-1000px);
							transform: translateX(-1000px);
							opacity: 0;
						}
					}
					@keyframes slide-out-left {
						0% {
							-webkit-transform: translateX(0);
							transform: translateX(0);
							opacity: 1;
						}
						100% {
							-webkit-transform: translateX(-1000px);
							transform: translateX(-1000px);
							opacity: 0;
						}
					}
				`}
			</style>
		</>
	);
};
export default connect(mapStateToProps, { getMoveplan, track })(LeftNav);

import React from 'react';

const Legal = () => (
	<div className="page-view legal-view">
		<div className="container">
			<div className="row">
				<div className="col-12 col-md-8 offset-md-2">
					<div className="panel b-b-1 p-l-20 p-t-20 p-r-20 p-b-20 term-conditions">
						<h1 style={{ marginTop: '40px' }}>You and Flatrate</h1>
						<ol>
							<li>
								Flatrate will require Movers and/or Storage Operators to honor
								the Guaranteed Price and/or Monthly Storage Charges. Yes, those
								are real prices you see in the compare chart. And, more
								importantly, Movers and/or Storage Operators will not be allowed
								to surprise you with additional fees or extra charges as long as
								the shipment details or requested services do not change.
							</li>
							<ol type="a">
								<li>
									If you do change your shipment details or request additional
									services, Movers and/or Storage Operators should change the
									Guaranteed Price and/or Monthly Storage Charges through the
									Flatrate site; as you can see, Flatrate takes pricing very
									seriously.
								</li>
							</ol>
							<li>
								Movers and/or Storage Operators must pass a screening process
								before becoming an Flatrate Member. We do the research you don’t
								have time to do to make sure you that are only dealing with
								reliable, professional moving companies.
							</li>
							<li>
								Movers and/or Storage Operators will Practice the Highest Level
								of Professionalism. If you have problems with the service you
								are receiving you can reach out to Flatrate for assistance. We
								are your advocate for the best move possible.
							</li>
							<ol type="a">
								<li>
									Movers and/or Storage Operators will properly wrap and/or
									protect your goods and your home or building’s floors and wall
									corners.
								</li>
								<li>
									Movers and/or Storage Operators will use the proper materials
									and equipment.
								</li>
								<li>
									Movers and/or Storage Operators will actively communicate with
									you before, during and after the services.
								</li>
								<li>
									If you need additional services or need to move additional
									goods, any changes will be entered and priced through
									Flatrate; ensuring that prices don't change without reason.
								</li>
								<li>
									Movers and/or Storage Operators will respect your preferences.
								</li>
								<li>Movers and/or Storage Operators will be punctual.</li>
								<li>
									Movers and/or Storage Operators will handle your customer
									service matters and claims in a respectful manner.
								</li>
								<li>
									Movers and/or Storage Operators that violate these or any of
									Flatrate's policies will be removed from Flatrate.
								</li>
							</ol>
							{/* <li>
								Movers and/or Storage Operators Must Adhere to Flatrate's
								Service Standards. For a full description of Flatrate's Service
								Standards,{' '}
								<a
									className="term-link"
									href="https://www.unpakt.com/best-moving-companies-best-prices/">
									click here
								</a>
								.
							</li> */}
						</ol>

						<p style={{ lineHeight: '20px' }}>
							Welcome to the Flatrate.com site (the “Site”). We are pleased to
							offer you access to the Site and use of our services conditioned
							on your acceptance without modification of the following Terms of
							Service and additional terms incorporated by reference herein
							(collectively, these “Terms”). This Site is provided solely to
							assist customers in gathering shipping information, determining
							the availability of shipping-related goods and services,
							transacting business with participating transportation service
							providers (“Movers” or "Storage Operators"), and for no other
							purposes (the “Service”). The terms "we," "us," "our" and
							"Flatrate" refer to Flatrate, LLC and its affiliates. The terms
							“user,” "you," and “your” refers to the customer visiting the Site
							and/or using the Service.
						</p>

						<p style={{ lineHeight: '20px' }}>
							FLATRATE IS NOT A BROKER OF HOUSEHOLD GOODS. FLATRATE DOES NOT
							ARRANGE (OR OFFER TO ARRANGE) FOR THE TRANSPORTATION OF HOUSEHOLD
							GOODS. Flatrate's ROLE IS LIMITED TO PROVIDING AN ELECTRONIC
							PLATFORM ON WHICH MEMBER MOVERS AND USERS COME TOGETHER AND A
							COMMON LANGUAGE FOR MEMBER MOVERS AND USERS TO SPEAK. FLATRATE IS
							ALSO NOT A REAL ESTATE BROKER. FLATRATE IS NOT RESPONSIBLE FOR,
							AND DOES NOT ENGAGE IN, BROKERING, SELLING, PURCHASING,
							EXCHANGING, OR LEASING POSTED PROPERTIES. FLATRATE DOES NOT
							COUNSEL LESSORS OR LESSEES, SHOW PROPERTIES, NEGOTIATE SALES
							CONTRACTS, OR HOLD A POSITION OF TRUST AND CONFIDENCE, WHETHER OR
							NOT IN CONNECTION WITH THE SERVICE. Flatrate's ROLE IN THE RENTAL
							OF STORAGE SPACE IS LIMITED TO PUBLISHING ADVERTISING PROVIDED BY
							STORAGE OPERATORS AND COMMUNICATING USERS’ REQUESTS FOR
							RESERVATIONS TO SAID STORAGE OPERATORS. NO BROKERAGE RELATIONSHIP
							OR ANY AGENCY OR FIDUCIARY RELATIONSHIP IS INTENDED TO BE OR SHALL
							BE DEEMED TO HAVE BEEN CREATED BETWEEN FLATRATE AND ANY MEMBER
							MOVERS, STORAGE OPERATORS AND/OR USERS.
						</p>

						<p style={{ lineHeight: '20px' }}>
							PLEASE READ THE FOLLOWING TERMS OF SERVICE CAREFULLY, AS THEY
							CONSTITUTE A BINDING LEGAL AGREEMENT BETWEEN YOU AND FLATRATE. BY
							REGISTERING FOR, ACCESSING, AND/OR OTHERWISE USING THE SITE IN ANY
							MANNER, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE
							TO BE BOUND BY THE FOLLOWING TERMS, INCLUDING THE GUIDELINES (AS
							DEFINED BELOW) AND ANY FUTURE MODIFICATIONS, AND ALL POLICIES AND
							GUIDELINES OF THE SITE ARE INCORPORATED BY REFERENCE. IF YOU DO
							NOT AGREE TO THESE TERMS, PLEASE DO NO USE THE SERVICE. WE RESERVE
							THE RIGHT, AT OUR SOLE DISCRETION AND AT ANY TIME, TO CHANGE OR
							MODIFY THESE TERMS WITHOUT PRIOR NOTICE, AND YOUR CONTINUED ACCESS
							OR USE OF THIS SITE SIGNIFIES YOUR ACCEPTANCE OF THE UPDATED OR
							MODIFIED TERMS. THESE TERMS MAY NOT BE MODIFIED, AMENDED, AND/OR
							CHANGED BY YOU IN ANY MANNER.
						</p>

						<ol type="I">
							<li>
								<p style={{ lineHeight: '20px' }}>
									Eligibility. THE SERVICE IS NOT INTENDED FOR OR AVAILABLE TO
									PERSONS UNDER THE AGE OF 18 OR TO ANY USERS SUSPENDED OR
									REMOVED FROM THE SERVICE. BY USING THE SERVICE, YOU REPRESENT
									THAT YOU ARE AT LEAST 18 YEARS OF AGE AND HAVE NOT BEEN
									PREVIOUSLY SUSPENDED OR REMOVED FROM THE SERVICE. IF YOU ARE
									UNDER 18 YEARS OF AGE BUT HAVE IMPROPERLY ACCESSED THE SERVICE
									BY PROVIDING FALSE INFORMATION TO US, NOT ONLY ARE YOU AN
									UNAUTHORIZED USER USING THE SERVICE IN VIOLATION OF THESE
									TERMS OF SERVICE, BUT YOU MAY ALSO BE PUTTING YOURSELF AND
									OTHERS IN DANGER.
								</p>
							</li>
							<li>
								<p style={{ lineHeight: '20px' }}>
									Services Provided by Flatrate. Subject to your compliance with
									these Terms, we grant you permission to access and use the
									Site and the Service. Flatrate offers you a neutral platform
									where you can find Movers and/or Storage Operators for your
									shipping and storage needs. Flatrate does not itself provide
									transportation and/or storage or assume carrier, valuation or
									insurance obligations. Based on the specifications that you
									enter into our system, such as inventory and services
									requested, our proprietary technology will generate and
									provide you with quotes from participating Movers and/or
									Storage Operators, allowing you to choose the provider that
									best suits your needs. If you make changes to the
									specifications of your move, such as additional inventory or
									services, the Guaranteed Price may increase. You will be
									required to pay for any increase to your Guaranteed Price
									which is the result of specification changes made prior to the
									scheduled move and/or storage date. Once you choose a Mover
									and/or Storage Operator, Flatrate has no involvement or
									control over the actual transaction. You will enter into
									separate agreement(s) with the Mover and/or Storage Operator
									of your choice regarding the actual shipping and/or storage
									terms; such agreements may include, but are not limited to, a
									Written Estimate, Order for Service, Bill of Lading, Storage
									Contract, or Storage Lease. Although Flatrate will require all
									Movers and/or Storage Operators to agree to provide the
									requested services at the estimated price generated through
									the Site, and will contractually restrict Movers and/or
									Storage Operators from changing the estimated price generated
									through the site (the “Guaranteed Price” or "Monthly Storage
									Charges"), Flatrate cannot and will not have any control over
									the Movers' and/or Storage Operator's adherence to these or
									any other terms of service; therefore, in addition to the
									disclaimers hereinafter described, Flatrate makes no
									representations or warranties that the Guaranteed Price and/or
									Monthly Storage Charge will be honored or unaltered by Movers
									and/or Storage Operators.
								</p>
								<ol type="a">
									<li style={{ lineHeight: '20px' }}>
										Move Now. An Flatrate user will also have the option to
										utilize the Move Now feature which will operate in much the
										same way as the Service as set forth herein (“Move Now”)
										(Move Now shall be incorporated into and fall within the
										definitions, terms and conditions of and related to the
										Service as set forth herein); specifically Move Now will:
										<ol type="i">
											<li style={{ marginTop: '20px' }}>
												Utilizing its proprietary system, and taking into
												account those Member Movers with the requisite
												availability and capability based on the details entered
												by the respective Flatrate user and as indicated by each
												Member Mover (“MN Available Member Movers”), will choose
												the Guaranteed Prices and/or Monthly Storage Fees from
												the three (3) most appropriate MN Available Member
												Movers (“MN Initial Three”) and then, from the MN
												Initial Three the one
												<ol>
													<li style={{ marginTop: '20px' }}>
														most appreciate Member Mover (“MN Final One”) using
														the following factors:
														<ol type="1">
															<li style={{ marginTop: '20px' }}>
																The Guaranteed Prices and/or Monthly Storage
																Fees as generated using the Pricing Matrix;
															</li>
															<li style={{ marginTop: '20px' }}>
																Any available Flatrate reviews of the MN
																Available Members Movers;
															</li>
															<li style={{ marginTop: '20px' }}>
																Any service issues of the MN Available Member
																Movers, including, but not limited to,
																cancellations, rejections and so forth; and/or,
															</li>
															<li style={{ marginTop: '20px' }}>
																Other factors that would be reasonable for the
																system to utilized in helping the Flatrate user
																in their ultimate choice of which Member Mover
																to utilize.
															</li>
														</ol>
													</li>
												</ol>
											</li>
											<li style={{ marginTop: '20px' }}>
												The System will present the Flatrate user with the MN
												Final One as well as with the ability to view the MS
												Initial Three along with information that the Flatrate
												user will need to make the ultimate decision of which
												Member Mover to utilize, and the respective Guaranteed
												Prices and/or Monthly Storage Fees of each plus a
												standard mark up of up to ten percent (10%) (“MN Mark
												Up”).
											</li>
										</ol>
									</li>
								</ol>
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Changes. Flatrate reserves the right, at its sole discretion, to
								make changes to or modify the Service and these Terms without
								notice. Your continued use or access of the Service and/or Site
								signifies acceptance of the updated or modified Service under
								these Terms. Flatrate may offer new products and/or services
								through the Site, which will also be subject to these Terms. You
								should visit the Site and review these Terms periodically to
								ensure you stay informed about the latest updates.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Additional Terms and Policies. Your use of the Site and/or
								Service is subject to, and you shall at all times comply with,
								the Flatrate Privacy Policy (“Privacy Policy”), which is hereby
								incorporated into and made a part of these Terms by reference,
								and subject to change without notice.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Prohibited and Restricted Items. Movers and/or Storage Operators
								may not transport or store bank bills, coin or currency, deeds,
								notes drafts or valuable papers of any kind, jewelry, postage
								stamps, stamp collections, revenue stamps, letters or packets of
								letters, photographs, precious stones, firearms, ammunition or
								contraband, or articles manufactured there from or perishable
								articles.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Billing, Payments, and Fees. You authorize Flatrate to charge
								you directly for the fees associated with the transportation
								and/or storage services provided by Movers and/or Storage
								Operators and to retain its fees through and from this
								transaction. Flatrate will charge your account at least two (2)
								business days prior to the scheduled move and/or storage date.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Reversals, Chargebacks and Claims. You agree that you will not
								file a claim or Chargeback without first making a good faith
								effort to remedy the situation directly with Movers, Storage
								Operators and/or Flatrate. If you file a claim or a Chargeback,
								or if you are successful in the reversal of the payment, you
								agree to and authorize your credit card company or debit or
								credit card issuing bank to allow Flatrate to retain or collect
								its fees. "Chargeback" means a request that a buyer files
								directly with his or her debit or credit card company or debit
								or credit card issuing bank to invalidate a payment.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Cancellations. If you cancel your move less than two (2)
								business days prior to the scheduled move and/or storage date
								you will be charged a cancellation charge in the amount of ten
								percent (10%) of the Guaranteed Price (“Cancellation Charge”).
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Feedback. After completion of your transaction, Flatrate will
								ask you for feedback on your experience transacting with the
								participating Movers and/or Storage Operators. We will use this
								feedback to improve our Service by allowing other customers to
								view your comments to inform their selection process. You agree
								that Flatrate shall acquire, and you hereby grant and otherwise
								transfer, any and all right, title, and interest in and to any
								actual or suggested modifications, design changes, improvements,
								and other information regarding the features and performance of
								the Service you received through Flatrate, including the use of
								your name or other identifying marks, and move details, without
								the payment of additional consideration.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								User Disagreements. Disagreements you may have with a
								participating Mover and/or Storage Operator over a transaction
								initiated through or related to the Services shall remain solely
								between you and the Mover and/or Storage Operator. Although
								Flatrate may attempt to mediate any disagreements between you
								and participating Movers and/or Storage Operators, Flatrate
								shall have no liability to you or to the participating Mover
								and/or Storage Operator for a failed or unsatisfactory
								transaction.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Term and Termination. These Terms will remain effective until
								terminated as set forth here. Flatrate may terminate or suspend
								your account and use of and access to the Service at its sole
								discretion, with or without cause, without notice or liability
								to you. You may terminate your use of the Service at any time
								and for any reason or no reason by stopping all use of the Site
								and Service and deleting your account profile on the Site. In
								the event of termination, Sections 7 through 19 shall survive.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								User Content. The Site may provide you and others with the
								opportunity to submit, post, display, transmit, perform,
								publish, distribute or broadcast content, materials and/or other
								information to Flatrate or the Site, including, without
								limitation, pictures, comments, ideas, reviews, and other
								content (collectively, “User Content”). Except as otherwise
								described in the Privacy Policy, you agree that your User
								Content will be treated as non-confidential and non-proprietary
								and will not be returned. You remain the owner of your User
								Content, but you acknowledge that Flatrate must have a license
								from you in order to accept your User Content. You grant to
								Flatrate the unrestricted, unconditional, unlimited, worldwide,
								irrevocable, perpetual and royalty-free right and license to
								host, use, copy, distribute, reproduce, disclose, sell, re-sell,
								sub-license, display, perform, transmit, publish, broadcast,
								modify and create derivative works of, reformat, translate,
								archive, store, cache or otherwise use in any manner whatsoever,
								all or any portion of your User Content for any purpose
								whatsoever in all formats; on or through any media, software,
								formula or medium now known or later developed; and with any
								technology or devices now known or later developed and to
								advertise, market and promote the same. You further agree that
								Flatrate is free to use any ideas, concepts, know-how or
								techniques contained in any User Content you send to the Site or
								Flatrate, for any purposes whatsoever, including, without
								limitation, developing, manufacturing and marketing products and
								or services using such User Content; or creating informational
								articles based on or advertising our products and services, and
								without obligation, remuneration or attribution of any kind to
								you or anyone else. You further perpetually and irrevocably
								grant Flatrate the unconditional right to use and exploit your
								name, persona and likeness included in any User Content and in
								connection with any User Content, without any obligation,
								remuneration or attribution of any kind to you or anyone else.
								You further authorize Flatrate to publish your User Content in a
								searchable format that may be accessed by users of the Site and
								the Internet. Except as prohibited by law, you waive any moral
								rights you may have in any User Content you submit, even if such
								User Content is altered or changed in a manner not agreeable to
								you. You agree that you either: (i) own the rights to the User
								Content you submit and the right to grant all of the rights and
								licenses in these Terms; or (ii) you have all necessary rights
								and licenses from the owner(s) of these rights to enter into
								these Terms and grant Flatrate these licenses. Upon Flatrate's
								request, you will furnish Flatrate any documentation,
								substantiation or releases necessary to verify your compliance
								with these Terms. You agree that Flatrate has no obligation to
								monitor or enforce your intellectual property rights to your
								User Content but has the right to protect and enforce its and
								its rights to your User Content including, without limitation,
								by bringing and controlling actions in your name and on your
								behalf (at Flatrate's cost and expense, to which you agree to
								consent and irrevocably appoint Flatrate as your
								attorney-in-fact, with the power of substitution and
								delegations, which appointment is coupled with an interest). You
								further acknowledge and agree that Flatrate does not and will
								not have any obligation to review, monitor, display, accept or
								exploit any User Content and Flatrate may, in its sole
								discretion, delete, move, re-format, edit, alter, distort,
								remove or refuse to exploit User Content at any time without
								notice or liability to you or any other party. You understand
								that User Content need not be maintained on the Site by us for
								any period of time and we reserve the right to delete it at any
								time. All of your User Content is your sole responsibility. This
								means that you, and not Flatrate, are entirely responsible for
								all User Content that you upload, post, e-mail, transmit or
								otherwise make available via the Site. If you post personal
								information to publicly available areas of the Site then you may
								receive unsolicited messages from third parties. Under no
								circumstances will we be liable in any way for any of User
								Content including, without limitation, any errors or omissions
								in User Content, or for any loss or damage of any kind incurred
								as a result of User Content. Flatrate explicitly retains all
								right, title, and interest in and to all intellectual property
								and other proprietary materials used in connection with the
								Service and/or the Site. Neither these Terms nor your use of the
								Service grant you any rights or license to use any Flatrate
								proprietary material except as authorized under these Terms.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Disclaimers; No Warranties. TO THE FULLEST EXTENT PERMISSIBLE
								PURSUANT TO APPLICABLE LAW, FLATRATE MAKES NO WARRANTY, EXPRESS,
								IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE SERVICE,
								AND EXPRESSLY DISCLAIM ANY WARRANTIES OF NONINFRINGEMENT,
								MERCHANTABILITY, FITNESS FOR ANY PARTICULAR PURPOSE, RESULTS, OR
								TITLE, AND ANY WARRANTIES IMPLIED BY A COURSE OF DEALING OR
								PERFORMANCE. FLATRATE DOES NOT WARRANT THE DATA, CONTENT,
								FEATURES, OR INFORMATION, INCLUDING SHIPMENT QUOTES, PROVIDED
								THROUGH THE RELEVANT SERVICE TO BE UNINTERRUPTED, ACCURATE, OR
								FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS. YOU ARE
								SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR EQUIPMENT, LOSS OF
								DATA, OR OTHER HARM THAT RESULTS FROM YOUR USE OF THE RELEVANT
								SERVICE. THE SERVICE CONTAINS INFORMATION PROVIDED TO THE SITE
								BY TRANSPORTATION SERVICE PROVIDERS. FLATRATE DOES NOT MONITOR,
								MODERATE, EDIT, CONFIRM, VET, VERIFY, OR OTHERWISE ENSURE THAT
								SUCH INFORMATION IS COMPLETE, ACCURATE, UP TO DATE, OR OTHERWISE
								SAFE TO USE OR RELY ON. FLATRATE DOES NOT HAVE ANY CONTROL OVER
								SUCH INFORMATION AND BEARS NO RESPONSIBILITY FOR IT OR ITS USE.
								THE ACTUAL SHIPMENT AND/OR STORAGE TRANSACTION IS SUBJECT TO
								TERMS BETWEEN YOU AND THE MOVER AND/OR STORAGE OPERATOR ONLY AND
								FLATRATE ASSUMES NO RESPONSIBILITY OVER QUALITY, SAFETY, AND
								LEGALITY OF THE TRANSACTION BETWEEN YOU AND THE MOVER AND/OR
								STORAGE OPERATOR. FLATRATE MAKES NO WARRANTIES AND PROVIDES NO
								ASSURANCES TO YOU UNDER THESE TERMS REGARDING THE COMPLETION OF
								A DELIVERY AND YOUR SATISFACTION WITH THE MOVERS’ AND/OR STORAGE
								OPERATORS PERFORMANCE. SOME JURISDICTIONS DO NOT ALLOW THE
								EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE ABOVE EXCLUSIONS
								MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY
								FROM STATE TO STATE AND JURISDICTION TO JURISDICTION.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Limitation of Liability and Damages. Flatrate has no control
								over the accuracy of the information provided by the Movers
								and/or Storage Operators to the Site (including that which is
								the basis of the quotes we generate), quality of the Mover
								and/or Storage Operator performance, or ability of a Mover
								and/or Storage Operator to provide the services for which you
								transact. Flatrate shall not be liable for any failed,
								incomplete, or unsatisfactory transaction or any other failure,
								technical or otherwise, of such transaction to occur as
								expected, including, but not limited to, any Movers’ and/or
								Storage Operator's refusal to honor a Guaranteed Price and/or
								Monthly Storage Charge or their modification thereof. IN NO
								EVENT SHALL FLATRATE BE LIABLE UNDER THESE TERMS FOR ANY
								CONSEQUENTIAL, SPECIAL, INDIRECT OR OTHER DAMAGES, OR LOST
								PROFITS, WHETHER BASED IN CONTRACT, TORT OR OTHERWISE, EVEN IF
								FLATRATE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
								THESE LIMITATIONS SHALL APPLY TO THE MAXIMUM EXTENT PERMITTED BY
								APPLICABLE LAW, REGARDLESS OF WHETHER FLATRATE MOVERS AND/OR
								STORAGE OPERATORS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
								DAMAGES AND REGARDLESS OF WHETHER ANY REMEDY FAILS OF ITS
								ESSENTIAL PURPOSE. YOU EXPRESSLY AGREE THAT IF FLATRATE IS FOUND
								LIABLE TO YOU NOTWITHSTANDING THE FOREGOING LIMITATIONS,
								Flatrate's MAXIMUM AGGREGATE LIABILITY TO YOU HEREUNDER, WHETHER
								ARISING FROM BREACH OF WARRANTY, BREACH OF CONTRACT, NEGLIGENCE,
								STRICT LIABILITY IN TORT, OR ANY OTHER LEGAL THEORY, SHALL NOT
								EXCEED THE FEES OR CHARGES ASSESSED BY, PAID TO AND RETAINED BY
								FLATRATE BY YOU IN CONNECTION WITH THE REQUEST PURSUANT TO WHICH
								THE LIABILITY AROSE. Without limiting the foregoing, Flatrate
								shall have no liability for any failure or delay resulting from
								any governmental action, fire, flood, insurrection, earthquake,
								power failure, riot, explosion, embargo, strikes whether legal
								or illegal, labor or material shortage, transportation
								interruption of any kind, work slowdown or any other condition
								affecting production or delivery in any manner beyond the
								control of Flatrate. By participating on the Site, Movers
								represent that they have valid registration, insurance, and
								licensure and that they will comply with federal and/or local
								laws, as applicable. Flatrate does not verify, prequalify, or
								validate these claims of Movers and hereby disclaims any
								liability to you due to the Movers’ representations.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Indemnification; Release. You agree to indemnify, defend, and
								hold Flatrate harmless from any claims, losses, damages,
								liabilities, including legal fees and expenses, arising out of
								your use or misuse of the Service, your violation of these Terms
								or any law, or any breach of the representations, warranties,
								and covenants made by you in these Terms. Flatrate reserves the
								right, at your expense, to assume the exclusive defense and
								control of any matter for which you are required to indemnify
								Flatrate, and you agree to cooperate with Flatrate's defense of
								these claims. Flatrate will use reasonable efforts to notify you
								of any such claim, action, or proceeding upon becoming aware of
								it. TO THE EXTENT PERMITTED UNDER APPLICABLE LAW, YOU HEREBY
								RELEASE FLATRATE FROM ANY AND ALL CLAIMS OR LIABILITY RELATED TO
								ANY PRODUCT, SERVICE, DEAL, OR TRANSPORTATION SERVICE PROVIDER,
								ANY ACTION OR INACTION BY A TRANSPORTATION SERVICE PROVIDER,
								INCLUDING A TRANSPORTATION SERVICE PROVIDER’S FAILURE TO COMPLY
								WITH APPLICABLE LAW AND/OR FAILURE TO ABIDE BY OR HONOR THE
								TERMS OF ANY TRANSACTION, AND ANY CONDUCT OR SPEECH, WHETHER
								ONLINE OR OFFLINE, OF ANY OTHER USER OF THE SERVICE.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								You understand that under Section 14104 of Title 49 USC, you are
								entitled to receive a written estimate for transporting your
								household goods shipment. You further understand that the Movers
								must conduct a physical survey of the articles you intend to
								move and must provide the estimate of charges based on the
								physical survey. You also understand that you may waive your
								right to the physical survey of your goods. Understanding your
								rights, by registering for, accessing, and/or otherwise using
								this Site in any manner, you indicate your desire to waiver your
								right to receive an estimate based on a physical survey of your
								shipment.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Dispute Resolution and Arbitration. Any controversy between you
								and Flatrate arising under, or in relation to, this agreement or
								related to the Site and/or Service shall be settled by binding
								arbitration. Such arbitration shall be held in the City of New
								York, in accordance with the laws of the State of New York and
								the rules of the American Arbitration Association.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Governing Law; Venue. These Terms: (i) will be governed by and
								construed in accordance with the laws of the State of New York,
								without giving effect to principles of conflicts of law; and
								(ii) will not be governed by the United Nations Convention of
								Contracts for the International Sale of Goods. To the extent
								that any lawsuit or court proceeding is permitted hereunder, you
								and Flatrate agree to submit to the personal and exclusive
								jurisdiction of the state courts and federal courts located
								within the District of New York for the purpose of litigating
								all such claims or disputes.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Miscellaneous. This is the entire agreement between you and
								Flatrate relating to the subject matter herein and will not be
								modified except in writing, signed by both parties, or by a
								change to these Terms made by Flatrate as set forth in Section
								III above. The relationship of the parties under these Terms is
								that of independent contractors, and these Terms will not be
								construed to imply that either party is the agent of the other.
								If any provision of these Terms shall be unlawful, void, or for
								any reason unenforceable, then that provision will be reformed
								to the minimum extent necessary to make it valid and
								enforceable, or, if not capable of reformation, will be deemed
								severable from these Terms, and will not affect the validity and
								enforceability of any remaining provisions. The Terms, and any
								rights and licenses granted hereunder, may not be transferred or
								assigned by you, including by operation of law or otherwise, but
								may be assigned by Flatrate without restriction. The heading
								references herein are for convenience only, do not constitute a
								part of these Terms, and will not be deemed to limit or affect
								any of the provisions hereof. Failure by Flatrate at any time to
								require performance of any provision of these Terms will in no
								manner affect Flatrate's right at a later time to enforce the
								same. The services hereunder are offered by Flatrate, LLC,
								located at 555 W 25th st, New York, NY 10001 and any notices
								hereunder should be sent to this address; Flatrate may be
								contacted by email at info@flatrate.com. Headings used herein
								are for reference purposes only, not for interpretation hereof.
							</li>
							<li style={{ lineHeight: '20px', marginTop: '20px' }}>
								Contact Us. If you have questions about our privacy practices or
								need assistance with exercising or understanding your privacy
								choices, please contact our Privacy Officer at
								customerservice@flatrate.com.
							</li>
							Last updated: May 12, 2021
						</ol>
					</div>
				</div>
			</div>
		</div>
	</div>
);

export default Legal;

import { handleActions } from 'redux-actions';
import has from 'lodash-es/has';
import {
	INVENTORY_SEARCH_FIELD_BLUR,
	INVENTORY_SEARCH_FIELD_FOCUS,
	UPDATE_CUSTOM_ITEMS,
	UPDATE_INVENTORY_BOXES,
	UPDATE_INVENTORY_ITEMS,
	UPDATE_INVENTORY_SEARCH_QUERY,
	UPDATE_ROOM_COUNTS,
	SET_ALL_INVENTORY,
	SET_HAS_TYPICALS,
	SET_INVENTORY_VIEW,
	SET_CURRENT_SECTION,
	SET_CURRENT_GROUP,
	UPDATE_INVENTORY_ROOMS,
	TOGGLE_HAS_TYPICAL_BOXES,
	UPDATE_SEARCH_RESULTS,
	SET_LAST_INVENTORY_ACTION,
	UPDATE_CUBIC_FEET,
	TOGGLE_SPECIAL_HANDLING_ASSEMBLY,
	TOGGLE_SPECIAL_HANDLING_CRATING,
	TOGGLE_SPECIAL_HANDLING_DISMOUNTING,
	INIT_SPECIAL_HANDLING_ITEMS,
	ADD_SPECIAL_HANDLING_ITEMS,
	SET_INVENTORY_PRICING,
	SET_INVENTORY_LOADING,
	SET_ADDED_TYPICALS,
	SET_SHOW_NO_BOXES_POP,
	SET_SUGGESTED_BOXES,
	REMOVE_ITEM,
	SET_IS_MODIFIED,
	SET_SELECTED_TAB,
} from '../actionTypes';

const initialState = {
	searchInFocus: false,
	searchQuery: '',
	filteredItems: {},
	allItems: [],
	allBoxes: [],
	boxOptions: {},
	inventoryRooms: [],
	inventoryItems: {},
	inventoryBoxes: {},
	roomCounts: [],
	customItems: {},
	hasTypicals: true,
	hasTypicalItems: true,
	hasTypicalBoxes: true,
	addedTypicals: false,
	currentInventoryView: 'INVENTORY',
	previousInventoryView: '',
	currentSection: '',
	currentGroup: '',
	currentGroupId: -1,
	pianoSupported: true,
	searchResults: [],
	searchResultsLength: 0,
	lastAction: {
		action: '',
		desc: '',
		image: '',
		type: '',
		active: false,
	},
	cubicFeet: 0,
	specialHandlingItems: {},
	pricing: {},
	loading: true,
	showNoBoxesPop: false,
	isModified: false,
	selectedTab: 0,
};

const inventoryReducer = handleActions(
	{
		[INVENTORY_SEARCH_FIELD_FOCUS]: state => ({
			...state,
			searchInFocus: true,
		}),
		[INVENTORY_SEARCH_FIELD_BLUR]: state => ({
			...state,
			searchInFocus: false,
		}),
		[UPDATE_INVENTORY_SEARCH_QUERY]: (state, { payload }) => {
			if (payload.query === null || payload.query.length === 0) {
				return {
					...state,
					searchQuery: '',
					currentInventoryView: state.previousInventoryView,
				};
			} else {
				if (state.currentInventoryView === 'SEARCH') {
					return {
						...state,
						searchQuery: payload.query,
					};
				} else {
					return {
						...state,
						searchQuery: payload.query,
						previousInventoryView: state.currentInventoryView,
						currentInventoryView: 'SEARCH',
						multiple: true,
						none: true,
					};
				}
			}
		},
		[UPDATE_INVENTORY_ROOMS]: (state, { payload }) => ({
			...state,
			inventoryRooms: payload,
		}),
		[UPDATE_INVENTORY_ITEMS]: (state, { payload }) => ({
			...state,
			inventoryItems: payload.inventoryItems,
		}),
		[UPDATE_INVENTORY_BOXES]: (state, { payload }) => ({
			...state,
			inventoryBoxes: payload.boxes,
		}),
		[UPDATE_ROOM_COUNTS]: (state, { payload }) => ({
			...state,
			roomCounts: payload.roomCounts,
		}),
		[UPDATE_CUSTOM_ITEMS]: (state, { payload }) => ({
			...state,
			customItems: payload.customItems,
		}),
		[SET_ALL_INVENTORY]: (state, { payload }) => ({
			...state,
			allItems: payload.allItems.items,
			allBoxes: payload.allItems.boxes,
		}),
		[SET_HAS_TYPICALS]: (state, { payload }) => {
			return {
				...state,
				hasTypicals: payload,
			};
		},
		[SET_INVENTORY_VIEW]: (state, { payload }) => {
			return {
				...state,
				currentInventoryView: payload,
			};
		},
		[SET_CURRENT_SECTION]: (state, { payload }) => {
			return {
				...state,
				currentSection: payload,
			};
		},
		[SET_CURRENT_GROUP]: (state, { payload }) => ({
			...state,
			currentGroup: payload.name,
			currentGroupId: payload.id,
		}),
		[TOGGLE_HAS_TYPICAL_BOXES]: state => {
			return {
				...state,
				hasTypicalBoxes: !state.hasTypicalBoxes,
			};
		},
		[UPDATE_SEARCH_RESULTS]: state => {
			const query = state.searchQuery.toLowerCase();
			let search = state.allItems.filter(item => {
				if (item.description !== null) {
					if (item.description.toLowerCase().includes(query)) {
						return true;
					}
				}
				return item.name.toLowerCase().includes(query);
			});
			let roomSorted = [];
			let count = 0;
			if (!!search.length) {
				count = search.length;
				if (count > 0) {
					for (let k = 1; k < 9; k++) {
						roomSorted.push(search.filter(item => item.room_id === k));
					}
				}
			}
			return {
				...state,
				searchResults: roomSorted,
				searchResultsLength: count,
			};
		},
		[INIT_SPECIAL_HANDLING_ITEMS]: (state, { payload }) => {
			let temp = {};
			payload.items.map(item => {
				if (!!item.mp_inventory_item_id.length) {
					temp[item.mp_inventory_item_id[0]] = item;
				} else temp[item.mp_inventory_item_id] = item;
				return true;
			});
			payload.customItems.map(customItem => {
				if (!!customItem.mp_inventory_item_id.length) {
					temp[customItem.mp_inventory_item_id[0]] = customItem;
				} else temp[customItem.mp_inventory_item_id] = customItem;
				return true;
			});
			return {
				...state,
				specialHandlingItems: temp,
			};
		},
		[SET_LAST_INVENTORY_ACTION]: (state, { payload }) => {
			switch (payload.type) {
				case 'ADD_TYPICALS':
					return {
						...state,
						lastAction: {
							multiple: true,
							active: true,
							desc: 'Typical furniture and boxes added',
						},
					};
				case 'REMOVE_TYPICALS':
					return {
						...state,
						lastAction: {
							multiple: true,
							active: true,
							desc: 'Typical furniture and boxes removed',
						},
					};
				case 'ADD_TYPICAL_BOXES':
					return {
						...state,
						lastAction: {
							multiple: true,
							active: true,
							desc: 'Typical boxes added',
						},
					};
				case 'REMOVE_TYPICAL_BOXES':
					return {
						...state,
						lastAction: {
							multiple: true,
							active: true,
							desc: 'Typical boxes removed',
						},
					};
				case 'ADD_ITEM':
					return {
						...state,
						lastAction: {
							action: 'added',
							multiple: false,
							active: true,
							desc: payload.payload.description,
							name: payload.payload.name,
							iconCssClass: payload.payload.icon_css_class,
						},
					};
				case 'REMOVE_ITEM':
					return {
						...state,
						lastAction: {
							action: 'removed',
							multiple: false,
							active: true,
							desc: payload.payload.description,
							name: payload.payload.name,
							iconCssClass: payload.payload.icon_css_class,
						},
					};
				case 'ADD_BOX':
					return {
						...state,
						lastAction: {
							action: 'added',
							multiple: false,
							active: true,
							desc: payload.payload.dimensions,
							name: payload.payload.name,
							iconCssClass: payload.payload.icon_css_class,
							type: 'box',
						},
					};
				case 'REMOVE_BOX':
					return {
						...state,
						lastAction: {
							action: 'removed',
							multiple: false,
							active: true,
							desc: payload.payload.dimensions,
							name: payload.payload.name,
						},
					};
				default:
					return state;
			}
		},
		[UPDATE_CUBIC_FEET]: (state, { payload }) => ({
			...state,
			cubicFeet: payload,
		}),
		[TOGGLE_SPECIAL_HANDLING_ASSEMBLY]: (state, { payload }) => {
			return {
				...state,
				specialHandlingItems: {
					...state.specialHandlingItems,
					[payload]: {
						...state.specialHandlingItems[payload],
						assembly_required:
							!state.specialHandlingItems[payload].assembly_required,
					},
				},
			};
		},
		[TOGGLE_SPECIAL_HANDLING_CRATING]: (state, { payload }) => {
			return {
				...state,
				specialHandlingItems: {
					...state.specialHandlingItems,
					[payload]: {
						...state.specialHandlingItems[payload],
						crating_required:
							!state.specialHandlingItems[payload].crating_required,
					},
				},
			};
		},
		[TOGGLE_SPECIAL_HANDLING_DISMOUNTING]: (state, { payload }) => {
			return {
				...state,
				specialHandlingItems: {
					...state.specialHandlingItems,
					[payload]: {
						...state.specialHandlingItems[payload],
						wall_removal_required:
							!state.specialHandlingItems[payload].wall_removal_required,
					},
				},
			};
		},
		[ADD_SPECIAL_HANDLING_ITEMS]: (state, { payload }) => ({
			...state,
			specialHandlingItems: {
				...state.specialHandlingItems,
				...payload,
			},
		}),
		[SET_INVENTORY_PRICING]: (state, { payload }) => ({
			...state,
			pricing: {
				inventoryItems: payload.inventoryItems,
				inventoryBoxes: payload.inventoryBoxes,
				customItems: payload.customItems,
			},
		}),
		[SET_INVENTORY_LOADING]: (state, { payload }) => ({
			...state,
			loading: payload,
		}),
		[SET_ADDED_TYPICALS]: (state, { payload }) => ({
			...state,
			addedTypicals: payload,
		}),
		[SET_SHOW_NO_BOXES_POP]: (state, { payload }) => ({
			...state,
			showNoBoxesPop: payload,
		}),
		[SET_SUGGESTED_BOXES]: state => ({
			...state,
			suggestedBoxes: state.allBoxes.map(box => {
				return {
					name: box.name,
					quantity: !!state.inventoryBoxes[box.box_type_id]
						? state.inventoryBoxes[box.box_type_id].quantity
						: 0,
				};
			}),
		}),
		[REMOVE_ITEM]: (state, action) => {
			let payload = action.payload;
			// if coming from rooms section, make payload the actual item to be removed
			if (!has(payload, 'mp_inventory_item_id')) {
				action.payload = {
					...state.inventoryItems[payload.room_id][payload.id],
				};
			}
			let tempArr = [
				...state.inventoryItems[payload.room_id][payload.id]
					.mp_inventory_item_id,
			];
			if (tempArr.length > 0) {
				tempArr.pop();
				return {
					...state,
					inventoryItems: {
						...state.inventoryItems,
						[payload.room_id]: {
							...state.inventoryItems[payload.room_id],
							[payload.id]: {
								...state.inventoryItems[payload.room_id][payload.id],
								mp_inventory_item_id: tempArr,
							},
						},
					},
				};
			} else {
				let roomObj = { ...state.inventoryItems[payload.room_id] };
				return {
					...state,
					[payload.room_id]: Object.keys(roomObj).reduce(
						(acc, cur) =>
							cur === payload.id ? acc : { ...acc, [cur]: roomObj[cur] },
						{}
					),
				};
			}
		},
		[SET_IS_MODIFIED]: (state, { payload }) => ({
			...state,
			isModified: payload,
		}),
		[SET_SELECTED_TAB]: (state, { payload }) => ({
			...state,
			selectedTab: payload,
		}),
	},
	initialState
);

export const totalBoxes = inventory => {
	let total = 0;
	Object.keys(inventory.inventoryBoxes).map(
		boxKey => (total += inventory.inventoryBoxes[boxKey].quantity)
	);
	return total;
};

export const getGroup = inventory => {
	return inventory.allItems.filter(
		group => group.group_id === inventory.currentGroupId
	);
};

export const getItemCount = (inventory, item) => {
	if (item.id in inventory.inventoryItems[item.room_id]) {
		return inventory.inventoryItems[item.room_id][item.id].mp_inventory_item_id
			.length;
	}
	if (item.id in inventory.customItems) {
		return inventory.customItems[item.id].mp_inventory_item_id.length;
	}
	return 0;
};

export const getBoxCount = (inventory, box_type_id) => {
	let count = 0;
	Object.keys(inventory.inventoryBoxes).map(boxKey => {
		if (inventory.inventoryBoxes[boxKey].box_type_id === box_type_id) {
			count = inventory.inventoryBoxes[boxKey].quantity;
		}
		return true;
	});
	return count;
};

export const getCommonItems = inventory => {
	let common = inventory.allItems.filter(item => item.common_item === true);
	let sorted = [];
	for (let k = 1; k < 9; k++) {
		sorted.push(common.filter(item => item.room_id === k));
	}
	return sorted;
};

export const getTotalCounts = inventory => {
	let itemSum = 0,
		boxSum = 0;
	inventory.roomCounts.map(room => (itemSum += room));
	Object.keys(inventory.inventoryBoxes).map(
		box => (boxSum += inventory.inventoryBoxes[box].quantity)
	);
	Object.keys(inventory.customItems).map(
		itemKey =>
			(itemSum += inventory.customItems[itemKey].mp_inventory_item_id.length)
	);
	return [itemSum, boxSum];
};

export const hasTypicals = inventory => {
	Object.keys(inventory.inventoryItems).forEach(i => {
		Object.keys(inventory.inventoryItems[i]).forEach(j => {
			if (!inventory.inventoryItems[i][j].is_user_selected) {
				return true;
			}
		});
	});
	return false;
};

export const hasTypicalBoxes = inventory => {
	Object.keys(inventory.inventoryBoxes).forEach(i => {
		if (!inventory.inventoryBoxes[i].is_user_selected) {
			return true;
		}
	});
	return false;
};

export const getSpecialHandlingCounts = inventory => {
	// counts[ wall, assembly, crating ]
	let counts = [0, 0, 0];
	Object.keys(inventory.inventoryItems).map(roomKey =>
		Object.keys(inventory.inventoryItems[roomKey]).map(itemKey => {
			if (inventory.inventoryItems[roomKey][itemKey].assembly_required) {
				counts[1] += 1;
			}
			if (inventory.inventoryItems[roomKey][itemKey].wall_removal_required) {
				counts[0] += 1;
			}
			if (inventory.inventoryItems[roomKey][itemKey].crating_required) {
				counts[2] += 1;
			}
			return true;
		})
	);
	Object.keys(inventory.customItems).map(itemKey => {
		if (inventory.customItems[itemKey].assembly_required) {
			counts[1] += 1;
		}
		if (inventory.customItems[itemKey].wall_removal_required) {
			counts[0] += 1;
		}
		if (inventory.customItems[itemKey].crating_required) {
			counts[2] += 1;
		}
		return true;
	});
	return counts;
};

export const pianoSupported = movePlan => {
	if (!!movePlan.currentPlan.mover) {
		return !!movePlan.currentPlan.mover.additional_services.does_piano;
	} else return false;
};

export const isInventoryModified = inventory => {
	let modArray = Object.keys(inventory.inventoryItems).map(roomKey => {
		let t = false;
		Object.keys(inventory.inventoryItems[roomKey]).forEach(itemKey => {
			if (inventory.inventoryItems[roomKey][itemKey].is_user_selected === true)
				t = true;
		});
		return t;
	});
	let isModified = false;
	modArray.forEach(i => {
		if (i === true) isModified = true;
	});
	return isModified;
};

export default inventoryReducer;

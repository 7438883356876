import React from 'react';
import Header from '../common/Header';
import { Link } from 'react-router-dom';

const About = () => {
	return (
		<div>
			<Header />
			<div className="p-3">
				<h1 style={{ fontSize: '32px', fontWeight: '600' }} className="mr-3">
					About us
				</h1>
				<Link
					style={{
						alignSelf: 'center',
						color: '#0abbb5',
						textDecoration: 'underline',
					}}
					to="/">
					Back to home
				</Link>

				<p className="mt-3">
					Move Now connects you with trusted movers in your area, making it fast
					and easy to plan your move.
				</p>
				<p>
					There is no shortage of moving companies, and it can be overwhelming
					to sort through them all. Don’t let the hassle of searching and
					researching take away the excitement of moving to a new place!
				</p>
				<p>
					With Move Now, you can find a trusted and affordable mover in a matter
					of moments.
				</p>
				<p>
					It’s easy: Just enter your starting and ending addresses and select
					the size of your home. Add your inventory and any other move details
					you have, and Move Now will do the searching for you. We instantly
					show you the best moving company rates - no guesswork! - and you can
					easily book online.
				</p>
				<p>
					All of Move Now’s movers are experienced, reliable, licensed, and
					insured, giving you peace of mind.
				</p>
			</div>
		</div>
	);
};

export default About;

import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import FullWidthLayout from './components/layouts/FullWidthLayout';
import AnalyticsWrapper from './components/common/AnalyticsWrapper';
import './scss/index.scss';

const App = () => {
	useEffect(() => {
		const env = process.env.REACT_APP_ENV;
		if (
			env === 'demo' ||
			env === 'test' ||
			window.location.href.indexOf('demo') > -1 ||
			window.location.href.indexOf('test') > -1
		) {
			const meta = document.createElement('meta');
			meta.name = 'robots';
			meta.content = 'nofollow, noindex';
			document.getElementsByTagName('head')[0].appendChild(meta);
		}
		return () => {
			try {
				localStorage.removeItem('persist:root');
			} catch (e) {
				return false;
			}
		}; //eslint-disable-next-line
	}, []);
	return (
		<AnalyticsWrapper>
			<FullWidthLayout />
		</AnalyticsWrapper>
	);
};

export default withRouter(App);

import React, { useState, useEffect } from 'react';
import Geosuggest from 'react-geosuggest';
import has from 'lodash-es/has';
import {
	setDropOff,
	setPickUp,
	setExtraDropOff,
	getDirection,
} from '../../../../../redux/actions/movePlan';
import { connect } from 'react-redux';
import { setInputFocusing } from '../../../../../redux/actions/common';

const DnaRow = ({
	addressKey,
	setFieldValue,
	value,
	formValue,
	placeholder,
	setDropOff,
	setPickUp,
	getDirection,
	setInputFocusing,
	setExtraDropOff,
	error,
	setFieldTouched,
}) => {
	// For Continue button validation, if the fieldValue is '', buttons is disabled
	const [apt, setApt] = useState('');

	const onChange = e => {
		if (e === '') {
			setFieldValue(`${addressKey}`, '');
		}
	};
	useEffect(() => {
		if (formValue && formValue.apartment_number) {
			setApt(formValue.apartment_number);
		}
		//eslint-disable-next-line
	}, [formValue]);
	// Need this because mobile keyboard pushes fixed element up.
	// element will disappear when focused.
	const inputGetsFocused = () => {
		setInputFocusing(true);
		setFieldTouched(`${addressKey}`, true);
		if (addressKey === 'drop_off') setDropOff(true);
		// remove blue-border in drop_off text field
	};
	const inputLosesFocused = () => {
		setInputFocusing(false);
	};

	const handleSelect = suggestion => {
		if (suggestion === undefined) return;
		console.log(suggestion);
		let components = {};
		suggestion.gmaps.address_components.map(
			value =>
				(components[value.types[0]] = [value.long_name, value.short_name])
		);
		if (
			components.locality === 'Washington' &&
			components.administrative_area_level_1 === 'District of Columbia'
		) {
			components.locality[0] = components.administrative_area_level_1.long_name;
			components.locality[1] =
				components.administrative_area_level_1.short_name;
		}
		let address = {};
		address = {
			lat: suggestion.location.lat,
			lng: suggestion.location.lng,
			geocoded_address: suggestion.description,
			street_address:
				!!components['street_number'] && components['route']
					? components['street_number'][0] + ' ' + components['route'][0]
					: null,
			city:
				!has(components, 'locality') &&
				!has(components, 'administrative_area_level_3') &&
				!has(components, 'administrative_area_level_1')
					? components['sublocality_level_1'][0]
					: has(components, 'locality')
					? components['locality'][0]
					: has(components, 'administrative_area_level_3')
					? components['administrative_area_level_3'][0]
					: components['administrative_area_level_2'][0],
			state: components['administrative_area_level_1'][1],
			zip: !!components['postal_code'] ? components['postal_code'][0] : null,
		};
		if (apt !== '') {
			address.apartment_number = apt;
		}
		setFieldValue(`${addressKey}`, address);

		// show up continue bar at the bottom
		setInputFocusing(false);
		// set marker on the google map
		if (addressKey === 'drop_off') {
			setDropOff(address);
			getDirection();
		} else if (addressKey === 'pick_up') {
			setPickUp(address);
			getDirection();
		} else if (addressKey === 'extra_drop_off') {
			setExtraDropOff(address);
		}
	};
	return (
		<Geosuggest
			initialValue={!!value ? value.geocoded_address : undefined}
			autoComplete="off"
			onSuggestSelect={handleSelect}
			types={['geocode']}
			placeDetailFields={['address_components']}
			country="US"
			autoActivateFirstSuggest={true}
			minLength={3}
			placeholder={placeholder}
			suggestsClassName={`suggestions-dropdown-full-width`}
			suggestItemClassName="suggestions-item"
			aria-label="Address"
			className="DnaRow-geosuggest"
			onChange={onChange}
			onFocus={inputGetsFocused}
			onBlur={inputLosesFocused}
			inputClassName={`${!!error && 'inner-input-error'}`}
			location={new window.google.maps.LatLng(40.712, -74.006)}
			radius="60"
		/>
	);
};

export default connect(null, {
	setDropOff,
	setPickUp,
	setExtraDropOff,
	getDirection,
	setInputFocusing,
})(DnaRow);

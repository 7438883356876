import { handleActions } from 'redux-actions';
import { FLOW_STEPS } from '../../constants';
import {
	SET_CURRENT_STEP,
	SET_LAST_STEP,
	SET_STEP_COMPLETED,
	RESET_FUNNEL,
} from '../actionTypes';

const initialState = {
	currentStep: '',
	lastStep: '',
	bodyClass: 'location-details-body',
	progressBarSteps: {
		INVENTORY: {
			id: 'inventory',
			title: 'Inventory',
			step: 'INVENTORY',
			isCompleted: true,
			nextStep: 'REVIEW',
		},
		DETAILS: {
			id: 'details',
			title: 'Details',
			step: 'DETAILS',
			isCompleted: false,
			nextStep: 'REVIEW',
		},
		REVIEW: {
			id: 'review',
			title: 'Review',
			step: 'REVIEW',
			isCompleted: false,
			nextStep: 'BOOK',
		},
		BOOK: {
			id: 'book',
			title: 'Book Online',
			step: 'BOOK',
			isCompleted: false,
			nextStep: 'CONGRATS',
		},
	},
};

const funnelReducer = handleActions(
	{
		[SET_CURRENT_STEP]: (state, { payload }) => ({
			...state,
			currentStep: payload,
		}),
		[SET_LAST_STEP]: (state, { payload }) => ({
			...state,
			lastStep: payload,
		}),
		[SET_STEP_COMPLETED]: (state, { payload }) => {
			if (
				payload !== 'INVENTORY' ||
				payload !== 'DETAILS' ||
				payload !== 'PLAN' ||
				payload !== 'BOOK' ||
				payload !== 'CONGRATS'
			) {
				return {
					...state,
					progressBarSteps: {
						...state.progressBarSteps,
						[payload]: {
							...state.progressBarSteps[payload],
							isCompleted: true,
						},
					},
				};
			}
		},
		[RESET_FUNNEL]: () => ({
			...initialState,
		}),
	},
	initialState
);

export default funnelReducer;

export const allowedToVisit = (state, action) => {
	if (state.auth.isAdmin || state.movePlan.currentPlan.is_read_only) {
		return true;
	}
	if (state.movePlan.currentPlan.is_read_only) {
		return true;
	}
	if (state.funnel.currentStep === 'PLAN' && action === 'print') {
		return true;
	}
	if (state.movePlan.currentPlan.is_done && state.funnel.action === 'PLAN') {
		return false;
	}
	if (state.auth.isMover) {
		if (action === 'PLAN') {
			return true;
		} else if (action === 'BOOK') {
			return false;
		} else return !state.movePlan.currentPlan.is_public;
	}

	if (!!state.movePlan.currentPlan.last_step) {
		return !!(
			FLOW_STEPS.indexOf(action) <=
			FLOW_STEPS.indexOf(state.movePlan.currentPlan.last_step.toUpperCase())
		);
	}
	return false;
};

export const toUSD = amount =>
	!!amount
		? new Intl.NumberFormat('en-US', {
				style: 'currency',
				currency: 'USD',
		  }).format(amount)
		: '$0';

export const getLastStep = steps => {
	let completedSteps = FLOW_STEPS.map(step =>
		steps[step].isCompleted ? step : null
	).filter(t => t);
	// fetch the last completed step
	return steps[steps[completedSteps[completedSteps.length - 1]].nextStep].id;
};

import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

const AsyncWhyMoveWithUs = lazy(() => import('./WhyMoveWithUs'));
const AsyncNumbers = lazy(() => import('./Numbers'));
const AsyncHowUnpaktWorks = lazy(() => import('../HowUnpaktWorks'));
const AsyncWhaCustomerSaying = lazy(() => import('./WhaCustomerSaying'));
const AsyncOtherServices = lazy(() => import('./OtherServices'));
// const AsyncMedia = lazy(() => import("./Media"));
const AsyncFooterDesktop = lazy(() => import('./FooterDesktop'));

const LandingContents = ({ viewModel, push }) => {
	return (
		<div>
			<Suspense fallback={''}>
				<AsyncWhyMoveWithUs />
			</Suspense>
			<Suspense fallback={''}>
				<AsyncNumbers />
			</Suspense>
			<Suspense fallback={''}>
				<AsyncHowUnpaktWorks viewModel={viewModel} />
			</Suspense>

			<div className="content-button__container">
				<button
					className="content-button--white mt-5 mb-5"
					onClick={() => push('/how-it-works')}>
					Learn More
				</button>
			</div>

			<Suspense fallback={''}>
				<AsyncWhaCustomerSaying />
			</Suspense>
			<Suspense fallback={''}>
				<AsyncOtherServices />
			</Suspense>

			<p className="right-container__title">Ready to get moving?</p>
			<div className="content-button__container mb-5">
				<button
					className="content-button--black mt-5 mb-5"
					onClick={() => push('/address')}>
					Get Started
				</button>
			</div>

			<Suspense fallback={''}>
				<AsyncFooterDesktop />
			</Suspense>
		</div>
	);
};

export default connect(null, { push })(LandingContents);

import React from 'react';
import Header from '../common/Header';
import { Link } from 'react-router-dom';

const HowItWorks = () => {
	return (
		<div>
			<Header />
			<div className="p-3">
				<h1 style={{ fontSize: '32px', fontWeight: '600' }}>How It Works</h1>
				<Link
					style={{
						alignSelf: 'center',
						color: '#0abbb5',
						textDecoration: 'underline',
					}}
					to="/">
					Back to home
				</Link>

				<h2 style={{ fontSize: '26px', fontWeight: '600', marginTop: '3vh' }}>
					Price
				</h2>
				<p className="mt-3">
					Put in your current address and move date, add the amount of stuff
					you’re bringing with you, and Move Now will connect you with a
					trusted, affordable moving company near you.
				</p>

				<h2 style={{ fontSize: '26px', fontWeight: '600' }}>Book</h2>
				<p>
					Book online and lock in your price. Don’t worry - you can make
					adjustments at any time, or cancel free of charge. You’ll only be
					charged two days before your move.
				</p>

				<h2 style={{ fontSize: '26px', fontWeight: '600' }}>Move</h2>
				<p>
					When moving day comes, you can relax! Move Now will handle your
					payments, so you don’t need to worry about surprise fees. All bookings
					made using Move Now include a full-service moving package.
				</p>
			</div>
		</div>
	);
};

export default HowItWorks;

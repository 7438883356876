import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { setEmailSent } from '../../redux/actions/plan';
import { emailPlan } from '../../redux/actions/movePlan';
import ReactLoading from 'react-loading';

const mapStateToProps = ({ auth, movePlan, plan }) => ({
	emailSent: plan.emailSent,
	emailError: plan.emailError,
	loading: movePlan.loading,
	hasMPID: !!auth.lastMPID,
});

const Email = props => {
	const [hasErrors, setErrors] = useState();
	useEffect(() => {
		props.setEmailSent(false); //eslint-disable-next-line
	}, []);
	const back = () => window.history.go(-1);
	return (
		<div className="container">
			<div className="row">
				<div className="m-t-10">
					<button type="button" onClick={back}>
						GO BACK
					</button>
				</div>
				<Formik
					initialValues={{ email: '' }}
					validateOnChange={false}
					validate={values => {
						let errors = {};
						if (!!!values.email) {
							errors.email = 'Email is required';
						} else if (
							!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
						) {
							errors.email = 'Invalid email address';
						}
						if (Object.keys(errors).length > 0) setErrors(errors.email);
						return errors;
					}}
					handleSubmit={e => e.preventDefault()}
					onSubmit={values => {
						props.emailPlan(values.email);
					}}
					render={({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						handleSubmit,
						setFieldError,
					}) => (
						<form
							className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3"
							noValidate>
							<div className="text-center m-l-20 m-r-20 m-b-20">
								<h1>EMAIL</h1>
							</div>
							<div className="moving-view-wrapper">
								<div className="col-12 p-b-10 m-t-20 m-b-20 required-input-container">
									<div>
										{!!props.emailSent ? (
											<div>
												<p>
													<span>Your move plan was sent to {values.email}</span>
												</p>
											</div>
										) : (
											<>
												<p> Email this plan to this address </p>
												<input
													type="email"
													name="email"
													onChange={handleChange}
													onFocus={() => {
														setErrors();
														setFieldError('email');
													}}
													value={values.email}
													onBlur={handleBlur}
													placeholder="Email"
													className={touched.email && errors.email && 'error'}
													required
													autoComplete="email"
												/>
											</>
										)}
										{!!hasErrors && (
											<p
												style={{
													color: 'red',
													margin: '5px 0 0',
													textAlign: 'center',
												}}>
												Please enter a valid email address
											</p>
										)}
									</div>
								</div>
							</div>
							{props.emailSent ? (
								<div className="col-12 p-t-10">
									<button
										type="button"
										className="btn btn-block btn-primary p-b-10 p-t-10"
										onClick={back}>
										Done
									</button>
								</div>
							) : (
								<div className="col-12 p-t-10">
									<button
										onClick={handleSubmit}
										className={`btn btn-block btn-primary p-b-10 p-t-10 ${
											!!!props.hasMPID && 'disabled'
										}`}
										disabled={!props.hasMPID}>
										{props.loading ? (
											<ReactLoading
												className="m-auto"
												type={'spokes'}
												color={'#ddd'}
												height={20}
												width={20}
											/>
										) : (
											'Send'
										)}
									</button>
								</div>
							)}
						</form>
					)}
				/>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, { emailPlan, setEmailSent })(Email);

import {
	GOTO_BOXES,
	SET_STEP_COMPLETED,
	GOTO_COMPARE,
	GOTO_LAST_STEP,
	GOTO_MY_INVENTORY,
	GOTO_DETAILS,
	GOTO_STEP,
	NEXT_STEP,
	PRINT_PAGE,
	SET_CURRENT_STEP,
	SET_LAST_STEP,
	GOTO_BOOK,
	GOTO_CONGRATS,
	GOTO_PLAN,
	IMPORT_FUNNEL_STATE,
	RESET_FUNNEL,
	SET_STEP,
	GOTO_REVIEW,
} from '../actionTypes';

export const nextStep = () => ({
	type: NEXT_STEP,
});

export const setCurrentStep = step => ({
	type: SET_CURRENT_STEP,
	payload: step,
});

export const setLastStep = step => ({
	type: SET_LAST_STEP,
	payload: step,
});

export const gotoMyInventory = () => ({
	type: GOTO_MY_INVENTORY,
});

export const gotoDetails = () => ({
	type: GOTO_DETAILS,
});

export const gotoReview = () => ({
	type: GOTO_REVIEW,
});

export const gotoCompare = () => ({
	type: GOTO_COMPARE,
});

export const gotoPlan = () => ({
	type: GOTO_PLAN,
});

export const gotoBook = () => ({
	type: GOTO_BOOK,
});

export const gotoCongrats = () => ({
	type: GOTO_CONGRATS,
});

export const gotoBoxes = () => ({
	type: GOTO_BOXES,
});

export const goToStep = step => ({
	type: GOTO_STEP,
	payload: step,
});

export const goToLastStep = () => ({
	type: GOTO_LAST_STEP,
});

export const printPage = (page, isCurrent) => ({
	type: PRINT_PAGE,
	payload: { page: page, isCurrentPage: isCurrent },
});

export const setStepCompleted = step => ({
	type: SET_STEP_COMPLETED,
	payload: step,
});

export const importFunnelState = lastStep => ({
	type: IMPORT_FUNNEL_STATE,
	payload: lastStep,
});

export const resetFunnel = () => ({
	type: RESET_FUNNEL,
});

export const setStep = (step, redirect) => ({
	type: SET_STEP,
	payload: step,
	meta: redirect,
});

import { handleActions } from 'redux-actions';
import { CLOSE_ALL_MODALS, CLOSE_LAST_MODAL, OPEN_MODAL } from '../actionTypes';
import { dropRight } from 'lodash/fp';

const initialState = [];

const modalsReducer = handleActions(
	{
		[OPEN_MODAL]: (state, action) => [...state, action.payload.modal],
		[CLOSE_LAST_MODAL]: state => dropRight(1, state),
		[CLOSE_ALL_MODALS]: () => initialState,
	},
	initialState
);

export default modalsReducer;

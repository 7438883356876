import {
	GET_SUGGESTIONS,
	SELECT_SUGGESTION,
	SET_SUGGESTIONS,
	SET_SCRIPT_LOADING,
	MOVE_MAP_TOP,
	SET_INPUT_FOCUSING,
	SET_HOME_SIZE_SELECTED,
	SET_TOGGLED_EXTRA_DROP_OFF,
	SET_HEADER_MARGIN,
	SET_RESCHEDULE_MODAL,
	SET_RESCHEDULE_LOADING,
} from '../actionTypes';

export const getSuggestions = (query, useExact) => ({
	type: GET_SUGGESTIONS,
	payload: { query: query, useExact: useExact },
});

export const setSuggestions = suggestions => ({
	type: SET_SUGGESTIONS,
	payload: suggestions,
});

export const selectSuggestion = (suggestion, key) => ({
	type: SELECT_SUGGESTION,
	payload: { suggestion: suggestion, key: key },
});

export const setScriptLoading = bool => ({
	type: SET_SCRIPT_LOADING,
	payload: bool,
});

export const setMoveMapTop = bool => ({
	type: MOVE_MAP_TOP,
	payload: bool,
});

export const setInputFocusing = bool => ({
	type: SET_INPUT_FOCUSING,
	payload: bool,
});

export const setHomeSizeSelected = bool => ({
	type: SET_HOME_SIZE_SELECTED,
	payload: bool,
});

export const setToggledExtraDropOff = bool => ({
	type: SET_TOGGLED_EXTRA_DROP_OFF,
	payload: bool,
});

export const setHeaderMargin = bool => ({
	type: SET_HEADER_MARGIN,
	payload: bool,
});

export const setRescheduleModal = payload => ({
	type: SET_RESCHEDULE_MODAL,
	payload: payload,
});

export const setRescheduleLoading = bool => ({
	type: SET_RESCHEDULE_LOADING,
	payload: bool,
});

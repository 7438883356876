import handleActions from 'redux-actions/es/handleActions';
import { STATES } from '../../constants';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import { SET_DETAILS_LOADING } from '../actionTypes';

const initialState = {
	loading: false,
};

const detailsReducer = handleActions(
	{
		[SET_DETAILS_LOADING]: (state, { payload }) => ({
			...state,
			loading: payload,
		}),
	},
	initialState
);

export const getStateAbbreviation = stateName =>
	find(STATES, ['full', stateName]);

export const getStateIndexFromFull = stateName =>
	findIndex(STATES, ['full', stateName]);

export const getStateIndexFromShort = stateName =>
	findIndex(STATES, ['value', stateName]);

export default detailsReducer;

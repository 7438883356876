import {
	SET_SUGGESTIONS,
	SELECT_SUGGESTION,
	SET_SCRIPT_LOADING,
	MOVE_MAP_TOP,
	SET_INPUT_FOCUSING,
	SET_HOME_SIZE_SELECTED,
	SET_TOGGLED_EXTRA_DROP_OFF,
	SET_HEADER_MARGIN,
	SET_RESCHEDULE_MODAL,
	SET_RESCHEDULE_LOADING,
} from '../actionTypes';
import { handleActions } from 'redux-actions';

const initialState = {
	suggestions: [],
	selected: {
		pick_up: {},
		drop_off: {},
	},
	scriptLoading: false,
	moveMapTop: false,
	inputFocusing: false,
	homeSizeSelected: false,
	toggledExtraDropOff: false,
	headerMargin: true,
	rescheduleModal: null,
	rescheduleLoading: false,
};

const commonReducer = handleActions(
	{
		[SET_SUGGESTIONS]: (state, { payload }) => ({
			...state,
			suggestions: payload,
		}),
		[SELECT_SUGGESTION]: (state, { payload }) => ({
			...state,
			selected: {
				...state.selected,
				[payload.key]: payload.suggestion,
			},
		}),
		[SET_SCRIPT_LOADING]: (state, { payload }) => ({
			...state,
			scriptLoading: payload,
		}),
		[MOVE_MAP_TOP]: (state, { payload }) => ({
			...state,
			moveMapTop: payload,
		}),
		[SET_INPUT_FOCUSING]: (state, { payload }) => ({
			...state,
			inputFocusing: payload,
		}),
		[SET_HOME_SIZE_SELECTED]: (state, { payload }) => ({
			...state,
			homeSizeSelected: payload,
		}),
		[SET_TOGGLED_EXTRA_DROP_OFF]: (state, { payload }) => ({
			...state,
			toggledExtraDropOff: payload,
		}),
		[SET_HEADER_MARGIN]: (state, { payload }) => ({
			...state,
			headerMargin: payload,
		}),
		[SET_RESCHEDULE_MODAL]: (state, { payload }) => ({
			...state,
			rescheduleModal: payload,
		}),
		[SET_RESCHEDULE_LOADING]: (state, { payload }) => ({
			...state,
			rescheduleLoading: payload,
		}),
	},
	initialState
);

export default commonReducer;

import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import LeftNav from './LeftNav';
import Header from '../common/Header';
import { useMedia } from '../start/landing/customHooks';

const AsyncMyHome = lazy(() => import('./myHome'));
const AsyncMoveHistory = lazy(() => import('./MoveHistory'));

const MyHomeContainer = props => {
	const viewModel = useMedia(
		[
			'(min-width: 1440px)',
			'(min-width: 993px)',
			'(min-width: 768px)',
			'(min-width: 1px)',
		],
		['wide', 'desktop', 'tablet', 'mobile'],
		'desktop'
	);
	return (
		<div>
			<Header changeTheme={true} />
			<LeftNav viewModel={viewModel} />

			<Switch>
				<Route
					exact
					path="/my-home"
					render={() => (
						<Suspense fallback={null}>
							<AsyncMyHome viewModel={viewModel} />
						</Suspense>
					)}
				/>
				<Route
					path="/my-home/move-history"
					render={() => (
						<Suspense fallback={null}>
							<AsyncMoveHistory {...props} viewModel={viewModel} />
						</Suspense>
					)}
				/>
			</Switch>
		</div>
	);
};

export default MyHomeContainer;

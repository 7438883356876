import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import {
	registerUser,
	setErrorMessage,
	setAuthSubmitting,
	checkEmailAvailability,
	setEmailTaken,
} from '../../../redux/actions/auth';
import { Container, Row } from 'reactstrap';
import has from 'lodash-es/has';
import ReactLoading from 'react-loading';

const mapStateToProps = ({ auth, movePlan }) => ({
	apiErrorText: auth.authErrorText,
	submitting: auth.submitting,
	isLoggedIn: has(auth, 'user') && auth.user,
	isEmailTaken: auth.isEmailTaken,
	userEmail: has(auth, 'user') && auth.user ? auth.user.email : null,
	lastMPID: !!auth.lastMPID ? auth.lastMPID : false,
	lastStep: movePlan.currentPlan.last_step,
});

const Register = props => {
	useEffect(() => {
		props.setErrorMessage('');
		props.setAuthSubmitting(false);
		props.setEmailTaken(false); //eslint-disable-next-line
	}, []);
	const handleFocusEmail = (e, setFieldError) => {
		if (!!props.isEmailTaken) {
			props.setEmailTaken(false);
		}
		setFieldError('email', '');
	};
	const handleBlurCheckEmail = e => {
		if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(e.target.value)) {
			props.checkEmailAvailability(e.target.value);
		}
	};
	return (
		<div className="container moving-view-wrapper">
			<Row>
				<div className="mt-4 mb-3 col-12 text-center font-weight-bold">
					<h1>Register</h1>
				</div>
			</Row>
			{props.isLoggedIn ? (
				<Row>
					<div className="col-12 moving-view-wrapper  pb-4 pt-4 ">
						<div className="col-12 panel text-center pt-3 pb-3">
							<h2>You're now registered as {props.userEmail}</h2>
							<hr />
							<a
								className="d-block "
								href={`${process.env.REACT_APP_BASE_URL}`}>
								Start a new Move
							</a>
							{!!props.lastMPID && (
								<Link to={`moving/${props.lastMPID}/review`} className="mt-2">
									Continue Last Move Plan
								</Link>
							)}
						</div>
					</div>
				</Row>
			) : (
				<Row>
					<div className="col-12">
						<Formik
							initialValues={{
								email: '',
								password: '',
								password_confirmation: '',
								agreement: false,
								showPassword: false,
							}}
							validateOnChange={false}
							validateOnBlur={false}
							validate={values => {
								let errors = {};
								if (!values.email) {
									errors.email = 'Email is required';
								} else if (
									!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
										values.email
									)
								) {
									errors.email = `"${values.email}" is not a valid email address.`;
								}
								if (!values.password) errors.password = 'Password is required';
								else if (values.password.length < 6)
									errors.password =
										'Password should be at least 6 characters long';
								else if (!/^\S+$/.test(values.password))
									errors.password = 'Password cannot contain spaces.';
								else if (!values.password_confirmation)
									errors.password_confirmation =
										'You must confirm the password.';
								else if (values.password !== values.password_confirmation)
									errors.password = 'Passwords do not match.';

								if (!!!values.agreement) {
									errors.agreement = true;
								}
								return errors;
							}}
							handleSubmit={e => e.preventDefault()}
							onSubmit={(values, { setSubmitting, setFieldValue }) => {
								if (props.isEmailTaken) return;
								let payload = values;
								payload.registration_domain = 'Move Now';
								delete payload.showPassword;
								delete payload.agreement;
								setFieldValue('showPassword', false);
								props.registerUser(payload);
								setSubmitting(false);
							}}>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setErrors,
								setFieldError,
							}) => (
								<div className="moving-view-wrapper container panel pb-4 pt-4 pl-0 pr-0">
									<form onSubmit={handleSubmit} autoComplete="off" noValidate>
										<Container>
											<Row>
												<div className="col-12 pl-0 pr-0">
													<Container>
														<Row>
															{errors.email && touched.email && (
																<div className="col-12 mb-2">
																	<div className="well error-well p-t-5 p-b-5 ">
																		<p className="f-s-xs-14 text-center mt-0 mb-0">
																			{errors.email}
																		</p>
																	</div>
																</div>
															)}
															{props.isEmailTaken && (
																<div className="col-12 mb-2">
																	<div className="well error-well p-t-5 p-b-5 ">
																		<p className="f-s-xs-14 text-center mt-0 mb-0">
																			This email is already registered.{' '}
																			<Link to="/sign-in">Sign in</Link>
																		</p>
																	</div>
																</div>
															)}
															<div className="col-12 mb-1">
																<input
																	type="email"
																	className={`required-input-container ${
																		touched.email && errors.email && 'error'
																	} ${props.isEmailTaken && 'error'}`}
																	onChange={handleChange}
																	onBlur={e => handleBlurCheckEmail(e)}
																	onFocus={e =>
																		handleFocusEmail(e, setFieldError)
																	}
																	value={values.email}
																	name="email"
																	placeholder="Email"
																	autoComplete="email"
																	required
																/>
															</div>
															{errors.password && touched.password && (
																<div className="col-12 mb-2">
																	<div className="well error-well p-t-5 p-b-5 ">
																		<p className="f-s-xs-14 text-center mt-0 mb-0">
																			{errors.password}
																		</p>
																	</div>
																</div>
															)}
															{errors.password_confirmation &&
																touched.password_confirmation && (
																	<div className="col-12 mb-2">
																		<div className="well error-well p-t-5 p-b-5 ">
																			<p className="f-s-xs-14 text-center mt-0 mb-0">
																				{errors.password_confirmation}
																			</p>
																		</div>
																	</div>
																)}
															<div className="col-6 pr-1 mb-0">
																<input
																	type={
																		values.showPassword ? 'text' : 'password'
																	}
																	className={`required-input-container mb-3 ${
																		touched.password &&
																		errors.password &&
																		'error'
																	}`}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	onFocus={() => setErrors({})}
																	value={values.password}
																	name="password"
																	placeholder="Password"
																	autoComplete="none"
																	required
																/>
															</div>
															<div className="col-6 required-input-container pl-1 mb-0">
																<input
																	type={
																		values.showPassword ? 'text' : 'password'
																	}
																	className={`required-input-container mb-3 ${
																		(errors.password_confirmation ||
																			errors.password) &&
																		'error'
																	}`}
																	onChange={handleChange}
																	onBlur={handleBlur}
																	onFocus={() => setErrors({})}
																	value={values.password_confirmation}
																	name="password_confirmation"
																	placeholder="Confirm Password"
																	autoComplete="none"
																	required
																/>
															</div>
															{errors.length > 0 && (
																<div className="well p-t-5 p-l-14 p-b-5 p-r-14 m-b-10 error-well">
																	<ul className="f-s-xs-14 m-b-0 p-l-15">
																		{errors.map(error => (
																			<li>error</li>
																		))}
																	</ul>
																</div>
															)}
														</Row>
													</Container>
												</div>
											</Row>
											<Row>
												<div className="col-8">
													<Link className="pl-1" to="/sign-in">
														Already have an account?
													</Link>
												</div>
												<div className="col-4">
													<span className="align-top pr-2">Show</span>
													<span
														className={`apple-switch-container ${
															!!values.showPassword && 'checked'
														}`}>
														<span
															className={`apple-switch-switch ${
																values.showPassword && 'checked'
															}`}>
															<input
																type="checkbox"
																className="apple-switch-switch"
																onChange={handleChange}
																onBlur={handleBlur}
																value={values.showPassword}
																name="showPassword"
															/>
														</span>
													</span>
												</div>
											</Row>
											{!!props.apiErrorText && (
												<Row>
													<div className="col-xs-12 col-sm-8 offset-sm-2  ">
														<hr />
														<div className="well error-well p-t-5 p-b-5 ">
															<p className="f-s-xs-14 text-center mt-0 mb-0">
																{props.apiErrorText}
															</p>
														</div>
													</div>
												</Row>
											)}
											<hr />
											<Row>
												<div className="col-12 unpakt-checkbox-container">
													<label>
														<input
															value={values.agreement}
															onChange={handleChange}
															onFocus={() => setFieldError('agreement')}
															type="checkbox"
															name="agreement"
															required
															className={`unpakt-checkbox ${
																errors.agreement && 'field-error'
															}`}
														/>
														<span>
															{' '}
															I have read, understand and accept the
															<Link
																className="terms-conditions d-inline-block"
																to="/legal"
																target="_blank"
																rel="noopener noreferrer">
																{' '}
																Terms and Conditions
															</Link>
														</span>
													</label>
													{errors.agreement && (
														<div className="col-12 mb-2">
															<div className="well error-well p-t-5 p-b-5 ">
																<p className="f-s-xs-14 text-center mt-0 mb-0">
																	You must agree to the terms to create an
																	account.
																</p>
															</div>
														</div>
													)}
												</div>
											</Row>
											<Row>
												<div className="col-12 mt-2">
													<button
														type="submit"
														disabled={
															props.submitting ||
															isSubmitting ||
															props.isEmailTaken
														}
														onClick={handleSubmit}
														className="btn btn-block btn-secondary filled login no-transition">
														{props.submitting || isSubmitting ? (
															<ReactLoading
																className="m-auto"
																type={'spokes'}
																color={'#ddd'}
																height={20}
																width={20}
															/>
														) : (
															'REGISTER'
														)}
													</button>
												</div>
											</Row>
										</Container>
									</form>
								</div>
							)}
						</Formik>
					</div>
				</Row>
			)}
		</div>
	);
};

export default connect(mapStateToProps, {
	registerUser,
	setErrorMessage,
	setAuthSubmitting,
	checkEmailAvailability,
	setEmailTaken,
})(Register);

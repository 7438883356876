import { CLOSE_ALL_MODALS, CLOSE_LAST_MODAL, OPEN_MODAL } from '../actionTypes';

export const openModal = modal => ({
	type: OPEN_MODAL,
	payload: {
		modal: {
			name: modal.name,
			properties: modal.properties,
			// Can describe other properties of the modal here
		},
	},
});

export const closeModal = () => ({
	type: CLOSE_LAST_MODAL,
});

export const closeAllModals = () => ({
	type: CLOSE_ALL_MODALS,
});

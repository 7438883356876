import { API_REQUEST, GEOCODING_API_REQUEST } from '../actionTypes';
import { networkFailure, networkPending, networkSuccess } from '../actions/api';
import axios from 'axios';

let axiosClient = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	withCredentials: true,
});

const apiMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);

		if (action.type !== API_REQUEST && action.type !== GEOCODING_API_REQUEST) {
			return;
		}
		const { url, method, params } = action.payload;
		const { fromAction, redirect, callback } = action.meta;

		const originalRequest = {
			url,
			method,
			params,
			fromAction,
			redirect,
			callback,
		};
		const onSuccess = response => {
			dispatch(networkSuccess({ response, originalRequest }));
		};

		const onFailure = response => {
			dispatch(networkFailure({ response, originalRequest }));
		};

		dispatch(networkPending({ originalRequest }));

		const paramsProperty = ['GET', 'DELETE'].includes(method)
			? 'params'
			: 'data';

		if (action.type === API_REQUEST) {
			axiosClient({
				method: method || 'GET',
				url,
				[paramsProperty]: params,
			})
				.then(onSuccess)
				.catch(onFailure);
		} else if (action.type === GEOCODING_API_REQUEST) {
			axios.get(url, { params: params }).then(onSuccess).catch(onFailure);
		}
	};

export default apiMiddleware;

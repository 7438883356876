import {
	FETCH_ONSITE_REQUESTS,
	GET_POTENTIAL_MOVERS,
	SELECT_CONSULTATION_MOVER,
	SEND_CONSULTATION_REQUEST,
	SET_CONSULTATION_DATE,
	SET_CONSULTATION_TIME,
	SET_CONSULTATIONS_MOVER,
	SET_CONSULTATIONS_TYPE,
	SET_CONTACT_INFO,
	SET_NO_MOVERS_FOUND,
	SET_ONSITE_REQUESTS,
	SET_POTENTIAL_MOVERS,
	TOGGLE_CONSULTATIONS_EDIT_MODE,
	TOGGLE_SUBMITTING,
} from '../actionTypes';

export const toggleConsultationsEditMode = index => ({
	type: TOGGLE_CONSULTATIONS_EDIT_MODE,
	payload: index,
});

export const setConsultationsMover = (index, mover) => ({
	type: SET_CONSULTATIONS_MOVER,
	payload: { index: index, mover: mover },
});

export const setConsultationDate = (index, date) => ({
	type: SET_CONSULTATION_DATE,
	payload: { index: index, date: date },
});

export const setContactInfo = contact => ({
	type: SET_CONTACT_INFO,
	payload: contact,
});

export const toggleConsultationsSubmitting = () => ({
	type: TOGGLE_SUBMITTING,
});

export const setConsultationsTime = (index, time) => ({
	type: SET_CONSULTATION_TIME,
	payload: { index: index, time: time },
});

export const getPotentialMovers = index => ({
	type: GET_POTENTIAL_MOVERS,
	payload: { index: index },
});

export const setPotentialMovers = movers => ({
	type: SET_POTENTIAL_MOVERS,
	payload: movers,
});

export const selectConsultationMover = (index, mover) => ({
	type: SELECT_CONSULTATION_MOVER,
	payload: { index: index, mover: mover },
});

export const setNoMoversFound = (index, noMoversFound) => ({
	type: SET_NO_MOVERS_FOUND,
	payload: { index: index, noMoversFound: noMoversFound },
});

export const sendConsultationRequest = () => ({
	type: SEND_CONSULTATION_REQUEST,
});

export const setConsultationType = type => ({
	type: SET_CONSULTATIONS_TYPE,
	payload: type,
});

export const fetchOnsiteRequests = () => ({
	type: FETCH_ONSITE_REQUESTS,
});

export const setOnsiteRequests = onsite_requests => ({
	type: SET_ONSITE_REQUESTS,
	payload: onsite_requests,
});

import {
	DELETE_PLAN,
	EMAIL_CHECKLIST,
	GET_ALL_PLANS,
	INIT_MYHOME,
	NETWORK_SUCCESS,
	PRINT_CHECKLIST,
	REUSE_PLAN,
	START_NEW_PLAN,
	VIEW_PLAN,
} from '../actionTypes';
import { apiRequest } from '../actions/api';
import { closeModal, openModal } from '../actions/modals';
import { getAllPlans, setAllPlans } from '../actions/myHome';
import { getCurrentMoveplan, setCurrentMoveplanID } from '../actions/movePlan';
import { track } from '../actions/analytics';
import {
	gotoBook,
	gotoCompare,
	gotoMyInventory,
	setStepCompleted,
} from '../actions/funnel';

const myHomeMiddleware =
	({ getState, dispatch }) =>
	next =>
	action => {
		next(action);
		if (action.type === INIT_MYHOME) {
			dispatch(getAllPlans());
		}

		if (action.type === EMAIL_CHECKLIST) {
			dispatch(
				apiRequest({
					url: `${process.env.REACT_APP_API_V1_URL}/users/move_checklist_email`,
					method: 'POST',
					fromAction: EMAIL_CHECKLIST,
				})
			);
		}

		if (action.type === PRINT_CHECKLIST) {
			// todo: implement firefox IE printing here

			// this works for safari and chrome
			let pri = document.getElementById('printframe').contentWindow;
			pri.focus();
			pri.print();
		}

		if (action.type === GET_ALL_PLANS) {
			dispatch(
				apiRequest({
					url: `api/v3/move_plans`,
					method: 'GET',
					fromAction: GET_ALL_PLANS,
				})
			);
		}

		if (action.type === START_NEW_PLAN) {
			dispatch(setCurrentMoveplanID(''));
			window.location.href = window.location.href =
				process.env.REACT_APP_MARKETING_URL;
		}

		if (action.type === REUSE_PLAN) {
			// todo: call the endpoint for reusing the plan
			// this will be called from the dna_modal component
		}

		if (action.type === VIEW_PLAN) {
			dispatch(track('myhome continued old plan ' + action.payload.status));
			if (action.payload.plan.status !== 'reserved') {
				dispatch(setCurrentMoveplanID(action.payload.plan.uuid));
				dispatch(gotoBook());
			} else {
				window.location.href = `https://tcs.unpakt.com/moving/${action.payload.plan.uuid}/plan`;
			}
		}

		if (action.type === DELETE_PLAN) {
			dispatch(track('deleted move plan'));
			dispatch(
				apiRequest({
					url: `api/v3/move_plans`,
					method: 'DELETE',
					params: {
						uuid: getState().myHome.userPlans.unbooked_move_plans[
							action.payload
						].uuid,
					},
					fromAction: DELETE_PLAN,
				})
			);
		}
		if (action.type === REUSE_PLAN) {
			dispatch(
				apiRequest({
					url: `/api/v3/move_plans/`,
					method: 'POST',
					params: {
						reused_move_plan_uuid: action.payload.uuid,
						move_plan: {
							drop_off_address: action.payload.move_plan.drop_off_address,
							home_size_id: action.payload.move_plan.home_size_id,
							move_date: action.payload.move_plan.move_date,
							pick_up_address: action.payload.move_plan.pick_up_address,
							white_label_name: 'Flatrate',
						},
					},
					fromAction: REUSE_PLAN,
				})
			);
		}

		if (action.type === NETWORK_SUCCESS) {
			switch (action.meta.originalRequest.fromAction) {
				case EMAIL_CHECKLIST:
					dispatch(
						openModal({
							name: 'emailMoveChecklist',
							properties: { email: getState().auth.user.email },
						})
					);
					break;
				case GET_ALL_PLANS:
					dispatch(setAllPlans(action.payload.response.data));
					dispatch(
						setCurrentMoveplanID(
							getState().myHome.userPlans.unbooked_move_plans[0].uuid
						)
					);
					dispatch(getCurrentMoveplan());
					break;
				case DELETE_PLAN:
					dispatch(getAllPlans());
					dispatch(closeModal());
					break;
				case REUSE_PLAN:
					// example response
					// {
					//  movers_all_consult_only: false
					//  movers_found: true
					//  uuid: "7c2c5eb0-920e-446a-a562-6a7b79151ee4"
					// }

					// eslint-disable-next-line
					let { uuid, movers_found, movers_all_consult_only } =
						action.payload.response.data;
					dispatch(setCurrentMoveplanID(uuid));
					dispatch(closeModal());
					if (movers_found) {
						dispatch(setStepCompleted('INVENTORY'));
						dispatch(setStepCompleted('DETAILS'));
						dispatch(gotoCompare());
					} else {
						dispatch(setStepCompleted('INVENTORY'));
						dispatch(gotoMyInventory());
					}
					break;
				default:
					break;
			}
		}
	};

export default myHomeMiddleware;

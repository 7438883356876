import {
	ADD_ITEM,
	ADD_TYPICAL_ITEMS,
	ADD_TYPICAL_BOXES,
	ADD_TYPICALS,
	REMOVE_TYPICALS,
	REMOVE_TYPICAL_ITEMS,
	REMOVE_TYPICAL_BOXES,
	FETCH_INVENTORY,
	FETCH_ALL_INVENTORY,
	INVENTORY_SEARCH_FIELD_BLUR,
	INVENTORY_SEARCH_FIELD_FOCUS,
	REMOVE_ITEM,
	UPDATE_CUSTOM_ITEMS,
	UPDATE_INVENTORY_BOXES,
	UPDATE_INVENTORY_ITEMS,
	UPDATE_ROOM_COUNTS,
	UPDATE_INVENTORY_SEARCH_QUERY,
	SET_ALL_INVENTORY,
	SET_INVENTORY_VIEW,
	SET_CURRENT_SECTION,
	SET_HAS_TYPICALS,
	SET_CURRENT_GROUP,
	TOGGLE_HAS_TYPICAL_BOXES,
	UPDATE_SEARCH_RESULTS,
	ADD_BOX,
	REMOVE_BOX,
	SET_LAST_INVENTORY_ACTION,
	UPDATE_CUBIC_FEET,
	CREATE_CUSTOM_ITEM,
	ADD_CUSTOM_ITEM,
	REMOVE_CUSTOM_ITEM,
	UPDATE_SPECIAL_HANDLING,
	TOGGLE_SPECIAL_HANDLING_ASSEMBLY,
	TOGGLE_SPECIAL_HANDLING_CRATING,
	TOGGLE_SPECIAL_HANDLING_DISMOUNTING,
	INIT_SPECIAL_HANDLING_ITEMS,
	ADD_SPECIAL_HANDLING_ITEMS,
	INIT_INVENTORY_PAGE,
	ADD_INVENTORY_PRICING,
	SET_SUGGESTED_BOXES,
	SET_SELECTED_TAB,
	SET_INVENTORY_PRICING,
	SET_INVENTORY_LOADING,
	SET_ADDED_TYPICALS,
	CREATE_NEW_PLAN,
	SET_SHOW_NO_BOXES_POP,
	UPDATE_ITEM_COUNT,
	UPDATE_BOX_COUNT,
	SET_IS_MODIFIED,
	INIT_INVENTORY_ROOMS,
	UPDATE_INVENTORY_ROOMS,
} from '../actionTypes';

export const initInventoryPage = movePlanId => ({
	type: INIT_INVENTORY_PAGE,
	payload: movePlanId,
});

export const inventorySearchFieldFocus = () => ({
	type: INVENTORY_SEARCH_FIELD_FOCUS,
});

export const inventorySearchFieldBlur = () => ({
	type: INVENTORY_SEARCH_FIELD_BLUR,
});

export const updateInventorySearchQuery = query => ({
	type: UPDATE_INVENTORY_SEARCH_QUERY,
	payload: { query },
});

export const fetchInventory = movePlanId => ({
	type: FETCH_INVENTORY,
	payload: { movePlanId },
});

export const fetchAllInventory = movePlanId => ({
	type: FETCH_ALL_INVENTORY,
	payload: { movePlanId },
});

export const setAllInventory = allItems => ({
	type: SET_ALL_INVENTORY,
	payload: { allItems },
});

export const updateInventoryItems = ({ inventoryItems }) => ({
	type: UPDATE_INVENTORY_ITEMS,
	payload: { inventoryItems },
});

export const updateRoomCounts = ({ roomCounts }) => ({
	type: UPDATE_ROOM_COUNTS,
	payload: { roomCounts },
});

export const updateInventoryBoxes = ({ boxes }) => ({
	type: UPDATE_INVENTORY_BOXES,
	payload: { boxes },
});

export const updateCustomItems = ({ customItems }) => ({
	type: UPDATE_CUSTOM_ITEMS,
	payload: { customItems },
});

export const addItem = item => ({
	type: ADD_ITEM,
	payload: item,
});

export const removeItem = item => ({
	type: REMOVE_ITEM,
	payload: item,
});

export const updateItemCount = (item, count) => ({
	type: UPDATE_ITEM_COUNT,
	payload: { count: count, item: item },
});

export const addBox = box => ({
	type: ADD_BOX,
	payload: box,
});

export const removeBox = box => ({
	type: REMOVE_BOX,
	payload: box,
});

export const updateBoxCount = (box, count) => ({
	type: UPDATE_BOX_COUNT,
	payload: { box: box, count: count },
});

export const createCustomItem = item => ({
	type: CREATE_CUSTOM_ITEM,
	payload: item,
});

export const addCustomItem = item => ({
	type: ADD_CUSTOM_ITEM,
	payload: item,
});

export const removeCustomItem = item => ({
	type: REMOVE_CUSTOM_ITEM,
	payload: item,
});

export const addTypicals = movePlanId => ({
	type: ADD_TYPICALS,
	payload: { movePlanId },
});

export const addTypicalItems = movePlanId => ({
	type: ADD_TYPICAL_ITEMS,
	payload: { movePlanId },
});

export const addTypicalBoxes = movePlanId => ({
	type: ADD_TYPICAL_BOXES,
	payload: { movePlanId },
});

export const removeTypicals = movePlanId => ({
	type: REMOVE_TYPICALS,
	payload: { movePlanId },
});

export const removeTypicalItems = movePlanId => ({
	type: REMOVE_TYPICAL_ITEMS,
	payload: { movePlanId },
});

export const removeTypicalBoxes = movePlanId => ({
	type: REMOVE_TYPICAL_BOXES,
	payload: { movePlanId },
});

export const setHasTypicals = bool => ({
	type: SET_HAS_TYPICALS,
	payload: bool,
});

export const toggleHasTypicalBoxes = () => ({
	type: TOGGLE_HAS_TYPICAL_BOXES,
});
export const setInventoryView = view => ({
	type: SET_INVENTORY_VIEW,
	payload: view,
});

export const setCurrentSection = section => ({
	type: SET_CURRENT_SECTION,
	payload: section,
});

export const setCurrentGroup = group => ({
	type: SET_CURRENT_GROUP,
	payload: group,
});

export const updateSearchResults = () => ({
	type: UPDATE_SEARCH_RESULTS,
});

export const setLastInventoryAction = action => ({
	type: SET_LAST_INVENTORY_ACTION,
	payload: action,
});

export const updateCubicFeet = cubes => ({
	type: UPDATE_CUBIC_FEET,
	payload: cubes,
});

export const initSpecialHandlingItems = payload => ({
	type: INIT_SPECIAL_HANDLING_ITEMS,
	payload: { items: payload.items, customItems: payload.customItems },
});

export const updateSpecialHandling = shouldFetch => ({
	type: UPDATE_SPECIAL_HANDLING,
	payload: shouldFetch,
});

export const toggleSpecialHandlingAssembly = id => ({
	type: TOGGLE_SPECIAL_HANDLING_ASSEMBLY,
	payload: id,
});

export const toggleSpecialHandlingCrating = id => ({
	type: TOGGLE_SPECIAL_HANDLING_CRATING,
	payload: id,
});

export const toggleSpecialHandlingDismounting = id => ({
	type: TOGGLE_SPECIAL_HANDLING_DISMOUNTING,
	payload: id,
});

export const addSpecialHandlingItems = payload => ({
	type: ADD_SPECIAL_HANDLING_ITEMS,
	payload: payload,
});

export const addInventoryPricing = () => ({
	type: ADD_INVENTORY_PRICING,
});

export const setInventoryPricing = payload => ({
	type: SET_INVENTORY_PRICING,
	payload: payload,
});

export const setInventoryLoading = bool => ({
	type: SET_INVENTORY_LOADING,
	payload: bool,
});

export const setAddedTypicals = bool => ({
	type: SET_ADDED_TYPICALS,
	payload: bool,
});

export const createNewPlan = plan => ({
	type: CREATE_NEW_PLAN,
	payload: plan,
});

export const setShowNoBoxesPop = bool => ({
	type: SET_SHOW_NO_BOXES_POP,
	payload: bool,
});

export const setSuggestedBoxes = () => ({
	type: SET_SUGGESTED_BOXES,
});

export const setIsModified = payload => ({
	type: SET_IS_MODIFIED,
	payload: payload,
});

export const initInventoryRooms = () => ({
	type: INIT_INVENTORY_ROOMS,
});

export const updateInventoryRooms = payload => ({
	type: UPDATE_INVENTORY_ROOMS,
	payload: payload,
});

export const setSelectedTab = payload => ({
	type: SET_SELECTED_TAB,
	payload: payload,
});

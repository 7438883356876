import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setScriptLoading } from '../../redux/actions/common';

const ScriptLoadContainer = props => {
	const [status, setStatus] = useState('start');
	let { src, setScriptLoading, srcId } = props;

	useEffect(() => setScriptLoading(true), [setScriptLoading]);
	useEffect(() => {
		const do_load = inputScript => {
			inputScript
				.then(function () {
					setStatus('done');
					setScriptLoading(false);
				})
				.catch(function () {
					setStatus('error');
					setScriptLoading(false);
				});
		};
		const new_script = src => {
			return new Promise(function (resolve, reject) {
				let script = document.createElement('script');
				script.src = src;
				script.defer = 'defer';
				script.async = 'true';
				script.crossorigin = 'anonymous';
				script.addEventListener('load', function () {
					resolve();
				});
				script.addEventListener('error', function (e) {
					reject(e);
				});
				if (!!srcId) script.id = srcId;
				document.body.appendChild(script);
			});
		};

		if (!!window.google && props.script === 'google') {
			setStatus('done');
			setScriptLoading(false);
		} else {
			let inputScript = new_script(src);
			setStatus('loading');
			setTimeout(function () {
				do_load(inputScript);
			}, 0);
		}
	}, [src, setScriptLoading, setStatus, srcId, props.script]);

	return status === 'done' && <>{props.children}</>;
};

export default connect(null, { setScriptLoading })(ScriptLoadContainer);

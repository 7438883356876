import React, { useState } from 'react';
import Header from '../common/Header';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import axios from 'axios';
import MaskedInput from 'react-text-mask';
import { Link } from 'react-router-dom';

const CustomerService = () => {
	const [formCompleted, setCompleted] = useState(false);

	return (
		<div>
			<Header />
			<div className="col-sm-12 col-md-8 pt-3 pb-5">
				<h1 style={{ fontSize: '30px', fontWeight: '600' }}>Contact Movenow</h1>

				<Link
					style={{
						alignSelf: 'center',
						color: '#0abbb5',
						textDecoration: 'underline',
					}}
					to="/">
					Back to home
				</Link>

				<p className="mt-3">
					We’re here to help. Send us a request and we’ll respond as quickly as
					we can. Whether you're a consumer trying to save time and money by
					comparing guaranteed prices, or a company looking to become a part of
					the best market place for moving resources, Movenow is here for you.
					Please feel free to reach out and contact us via phone, email or the
					contact form below.
				</p>

				{formCompleted ? (
					<div className="submit-success">
						✓ Thank you, your inquiry has been submitted to the Flatrate Team.
						We will respond as soon as we can.
					</div>
				) : (
					<Formik
						initialValues={{
							name: '',
							email: '',
							phone_number: '',
							subject: '',
							message: '',
						}}
						validate={values => {
							let errors = {};

							if (!values.email) {
								errors.email = 'Email Required';
							} else if (
								!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
							) {
								errors.email = 'Invalid email address';
							}

							if (!values.message) {
								errors.message = 'Message Required';
							}
							if (!values.phone_number) {
								errors.phone_number = 'Phone Number Required';
							}
							if (!values.name) {
								errors.name = 'Name Required';
							}
							if (!values.subject) {
								errors.subject = 'Subject Line Required';
							}

							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							axios
								.post(
									`${process.env.REACT_APP_BASE_API_URL}/api/v3/mailings/contact_us`,
									values
								)
								.then(res => {
									if (res.status === 200) {
										setCompleted(true);
									}
									setSubmitting(false);
								});
						}}>
						{({
							isSubmitting,
							values,
							errors,
							touched,
							handleBlur,
							handleChange,
						}) => (
							<Form noValidate>
								<div className="row mt-4 customer-service-field">
									<div className="col-sm-6">
										<Field
											className={`unpakt-textbox w-100 ${
												touched.name && errors.name ? 'field-error' : ''
											}`}
											type="text"
											name="name"
											placeholder="Name"
										/>
										<ErrorMessage
											className="custom-error pt-1"
											name="name"
											component="div"
										/>
									</div>

									<div className="col-sm-6">
										<Field
											className={`unpakt-textbox w-100 ${
												touched.email && errors.email ? 'field-error' : ''
											}`}
											type="email"
											name="email"
											placeholder="Email"
										/>
										<ErrorMessage
											className="custom-error pt-1"
											name="email"
											component="div"
										/>
									</div>

									<div className="col-sm-6">
										<MaskedInput
											value={values.phone_number}
											name="phone_number"
											onBlur={handleBlur}
											onChange={handleChange}
											className={`unpakt-textbox w-100 ${
												touched.phone_number && errors.phone_number
													? 'field-error'
													: ''
											}`}
											placeholder="Phone Number"
											guide={false}
											mask={[
												'(',
												/[1-9]/,
												/\d/,
												/\d/,
												')',
												' ',
												/\d/,
												/\d/,
												/\d/,
												'-',
												/\d/,
												/\d/,
												/\d/,
												/\d/,
											]}
											required
										/>
										<ErrorMessage
											className="custom-error pt-1"
											name="phone_number"
											component="div"
										/>
									</div>

									<div className="col-sm-6">
										<Field
											className={`unpakt-textbox w-100 ${
												touched.subject && errors.subject ? 'field-error' : ''
											}`}
											type="text"
											value={values.subject}
											name="subject"
											placeholder="Subject"
											component="select">
											<option defaultValue>Subject (Please Select)</option>
											<option value="Booking A Move">Booking A Move</option>
											<option value="Updating A Booked Move">
												Updating A Booked Move
											</option>
											<option value="Question About Billing">
												Question About Billing
											</option>
											<option value="Question About A Move Today">
												Question About A Move Today
											</option>
											<option value="Question About Completed Move">
												Question About Completed Move
											</option>
											<option value="Other">Other</option>
										</Field>

										<ErrorMessage
											className="custom-error pt-1"
											name="subject"
											component="div"
										/>
									</div>

									<div className="col-sm-12 mb-3 textarea">
										<Field
											component="textarea"
											className={`unpakt-textarea w-100 ${
												touched.message && errors.message ? 'field-error' : ''
											}`}
											name="message"
											placeholder="Briefly tell us about your question or issue."
										/>
									</div>

									<div className="col-sm-12">
										{!isSubmitting && !formCompleted && (
											<button
												className="contact-submit"
												type="submit"
												disabled={isSubmitting}>
												Submit
											</button>
										)}
									</div>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</div>
		</div>
	);
};

export default CustomerService;

import { handleActions } from 'redux-actions';
import { TIME_OPTIONS } from '../../constants';
import {
	SELECT_CONSULTATION_MOVER,
	SET_CONSULTATION_DATE,
	SET_CONSULTATION_TIME,
	SET_CONSULTATIONS_MOVER,
	SET_CONTACT_INFO,
	SET_NO_MOVERS_FOUND,
	SET_POTENTIAL_MOVERS,
	TOGGLE_SUBMITTING,
	TOGGLE_CONSULTATIONS_EDIT_MODE,
	SET_CONSULTATIONS_TYPE,
	SET_ONSITE_REQUESTS,
} from '../actionTypes';
import { addDays, isEqual, format } from 'date-fns';

const defaultMoment = addDays(Date.now(), 2);

const initialState = {
	options: [
		{
			editMode: true,
			mover: undefined,
			date: defaultMoment,
			time: TIME_OPTIONS[0],
			scheduled: false,
			id: 'schedule0',
			noMoversFound: false,
		},
		{
			editMode: false,
			mover: undefined,
			date: defaultMoment,
			time: '',
			scheduled: false,
			id: 'schedule1',
			noMoversFound: false,
		},
		{
			editMode: false,
			mover: undefined,
			date: defaultMoment,
			time: '',
			scheduled: false,
			id: 'schedule2',
			noMoversFound: false,
		},
	],
	movers: [],
	contact: {
		name: '',
		phone_number: '',
		street_address: '',
		apartment_number: '',
	},
	submitting: false,
	type: '',
	onsite_requests: [
		{
			date: '09-15-2018',
			time: '8:00 AM - 9:00 AM',
		},
	],
};

const consultationsReducer = handleActions(
	{
		[TOGGLE_CONSULTATIONS_EDIT_MODE]: (state, action) => ({
			...state,
			options: state.options.map((option, index) =>
				index === action.payload
					? {
							...option,
							editMode: !state.options[action.payload].editMode,
					  }
					: option
			),
		}),
		[SET_CONSULTATIONS_MOVER]: (state, action) => ({
			...state,
			options: state.options.map((option, index) =>
				index === action.payload.index
					? {
							...option,
							mover: action.payload.mover,
							scheduled: true,
					  }
					: option
			),
		}),
		[SET_CONSULTATION_DATE]: (state, action) => ({
			...state,
			options: state.options.map((option, index) =>
				index === action.payload.index
					? {
							...option,
							date: action.payload.date,
					  }
					: option
			),
		}),
		[SET_CONSULTATION_TIME]: (state, action) => {
			return {
				...state,
				options: state.options.map((option, index) =>
					index === action.payload.index
						? {
								...option,
								time: action.payload.time,
						  }
						: option
				),
			};
		},
		[SET_CONTACT_INFO]: (state, action) => ({
			...state,
			contact: action.payload,
		}),
		[TOGGLE_SUBMITTING]: state => ({
			...state,
			submitting: !state.submitting,
		}),
		[SET_POTENTIAL_MOVERS]: (state, action) => ({
			...state,
			movers: action.payload,
		}),
		[SELECT_CONSULTATION_MOVER]: (state, action) => ({
			...state,
			options: state.options.map((option, index) =>
				index === action.payload.index
					? {
							...option,
							mover: action.payload.mover,
							scheduled: true,
							editMode: false,
					  }
					: option
			),
		}),
		[SET_NO_MOVERS_FOUND]: (state, action) => ({
			...state,
			options: state.options.map((option, index) =>
				index === action.payload.index
					? {
							...option,
							noMoversFound: action.payload.noMoversFound,
					  }
					: option
			),
		}),
		[SET_CONSULTATIONS_TYPE]: (state, action) => ({
			...state,
			type: action.payload,
		}),
		[SET_ONSITE_REQUESTS]: (state, action) => ({
			...state,
			onsite_requests: action.payload,
		}),
	},
	initialState
);

export const hasSameDateTime = options =>
	[0, 1, 2].map(item =>
		options.map((option, i) => {
			if (i !== item) {
				return (
					options[i].time === options[item].time &&
					isEqual(options[i].date, options[item].date)
				);
			} else return null;
		})
	);

export const isConsultationTimeTaken = consultation =>
	consultation.options.some(option =>
		consultation.onsite_requests.some(request => request.time === option.time)
	) &&
	consultation.options.some(option =>
		consultation.onsite_requests.some(
			request => request.date === format(option.date, 'MM-DD-YYYY')
		)
	);

export default consultationsReducer;
